import React, { useEffect, useState } from 'react';
import '../../style/Newsfeed/Newsfeed.css';
import Header from '../Header and Footer/Header';
import axios from 'axios';

interface Post {
  id: number;
  author: string;
  content: string;
  likes: number;
  comments: number;
  shares: number;
  timestamp: string;
  image?: string;
}

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api`; // Update this to match your backend port

const Newsfeed: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get<Post[]>(`${API_BASE_URL}/post`);
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handleLike = async (id: number) => {
    try {
      await axios.post(`${API_BASE_URL}/api/post/${id}/like`);
      setPosts(posts.map(post =>
        post.id === id ? { ...post, likes: post.likes + 1 } : post
      ));
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading ...</p>
      </div>
    );
  }
  return (
    <div className="newsfeed-container">
      <Header />
      <div className="posts-container">
        {posts.map((post) => (
          <div className="post-card" key={post.id}>
            <div className="post-header">
              <img src="https://via.placeholder.com/40" alt="Avatar" className="avatar" />
              <div className="author-info">
                <h3>{post.author}</h3>
                <span className="timestamp">{new Date(post.timestamp).toLocaleString()}</span>
              </div>
              <button className="more-options">⋮</button>
            </div>
            <div className="post-content">{post.content}</div>
            {post.image && (
              <img src={post.image} alt="Post" className="post-image" />
            )}
            <div className="post-actions">
              <button className="action-button" onClick={() => handleLike(post.id)}>
                👍 Like {post.likes}
              </button>
              <button className="action-button">
                💬 Comment {post.comments}
              </button>
              <button className="action-button">
                🔁 Share {post.shares}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Newsfeed;
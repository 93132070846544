import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Notifications/OrderNotifications.css';

const OrderNotifications = () => {
    const [notifications, setNotifications] = useState<string[]>([]);
    const [connectionStatus, setConnectionStatus] = useState("Connecting...");

    useEffect(() => {
        const jwtToken = localStorage.getItem('jwtToken');

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_BASE_URL}/notificationHub`, {
                accessTokenFactory: () => jwtToken || ''
            })
            .withAutomaticReconnect()
            .build();

        // Handle connection lifecycle
        connection.start()
            .then(() => setConnectionStatus("Connected"))
            .catch(err => {
                console.error("Connection failed: ", err);
                setConnectionStatus("Disconnected");
            });

        // Handle automatic reconnection events
        connection.onreconnecting(() => setConnectionStatus("Reconnecting..."));
        connection.onreconnected(() => setConnectionStatus("Connected"));
        connection.onclose(() => setConnectionStatus("Disconnected"));

        // Listen for notifications from the server
        connection.on("ReceiveOrderNotification", (message: string) => {
            setNotifications(prev => [...prev, message]);
        });

        // Cleanup when component unmounts
        return () => {
            connection.stop();
        };
    }, []);

    return (
        <>
            <Header />
            <div className="notifications-container">
                <h3>Order Notifications</h3>
                <p className="connection-status">Status: {connectionStatus}</p>
                {notifications.length === 0 ? (
                    <p>No new notifications</p>
                ) : (
                    <ul className="notification-list">
                        {notifications.map((notification, index) => (
                            <li key={index} className="notification-item">
                                {notification}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <Footer />
        </>
    );
};

export default OrderNotifications;

import React, { useState, ChangeEvent, FormEvent } from 'react';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Business/ActivateBusinessAccount.css';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from 'react-router-dom';
import { FaSpinner, FaCheckCircle, FaRegFileAlt } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ActivateBusinessAccount: React.FC = () => {
  const [accountType, setAccountType] = useState<'personal' | 'business'>('personal');
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    fullName: '',
    contactEmail: '',
    contactPhone: '',
    businessName: '',
    registrationNumber: '',
    taxId: '',
  });

  // Individual document state
  const [proofOfId, setProofOfId] = useState<File | null>(null);
  const [registrationCertificate, setRegistrationCertificate] = useState<File | null>(null);
  const [taxCertificate, setTaxCertificate] = useState<File | null>(null);
  const [addressVerification, setAddressVerification] = useState<File | null>(null);

  const handleAccountTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccountType(e.target.value as 'personal' | 'business');
    setUserData({
      fullName: '',
      contactEmail: '',
      contactPhone: '',
      businessName: '',
      registrationNumber: '',
      taxId: '',
    });
    setProofOfId(null);
    setRegistrationCertificate(null);
    setTaxCertificate(null);
    setAddressVerification(null);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreed(e.target.checked);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  // Handlers for each document upload
  const handleProofOfIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProofOfId(e.target.files ? e.target.files[0] : null);
  };

  const handleRegistrationCertificateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegistrationCertificate(e.target.files ? e.target.files[0] : null);
  };

  const handleTaxCertificateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTaxCertificate(e.target.files ? e.target.files[0] : null);
  };

  const handleAddressVerificationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddressVerification(e.target.files ? e.target.files[0] : null);
  };

  const getUserIdFromToken = (): string | null => {
    const token = localStorage.getItem('jwtToken');
    if (!token) return null;

    try {
      const decodedToken = jwtDecode<{ userId: string }>(token);
      return decodedToken.userId;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return null;
    }
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!agreed) {
      toast.error('Please agree to the terms and conditions.');
      return;
    }

    if (accountType === 'business' && (!proofOfId || !registrationCertificate || !taxCertificate || !addressVerification)) {
      toast.error('Please upload all required business documents.');
      return;
    }

    if (accountType === 'personal' && !proofOfId) {
      toast.error('Please upload the required personal document.');
      return;
    }

    const userId = getUserIdFromToken();
    if (!userId) {
      toast.error('User ID not found. Please log in.');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('accountType', accountType);
      formData.append('fullName', userData.fullName);
      formData.append('contactEmail', userData.contactEmail);
      formData.append('contactPhone', userData.contactPhone);

      if (accountType === 'business') {
        formData.append('businessName', userData.businessName);
        formData.append('registrationNumber', userData.registrationNumber);
        formData.append('taxId', userData.taxId || '');
      }

      // Append documents to formData
      if (proofOfId) formData.append('proofOfId', proofOfId);
      if (registrationCertificate) formData.append('registrationCertificate', registrationCertificate);
      if (taxCertificate) formData.append('taxCertificate', taxCertificate);
      if (addressVerification) formData.append('addressVerification', addressVerification);

      // **Updated Axios POST request with the correct endpoint**
      const activateResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/BusinessAccount/activate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });

      if (activateResponse.status === 200) {
        toast.success('Your account activation request has been submitted successfully!');
        navigate('/SubmitRequestMessage'); // Redirect to dashboard or desired page
      }
    } catch (error) {
      console.error('Error activating account:', error);
      toast.error('There was an error submitting your activation request. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="activate-business-container">
        <div className="activation-form-wrapper">
          <h1 className="page-title">Activate Your Account</h1>
          <p className="account-type-instructions">Select your account type:</p>
          <div className="account-type-options">
            <label className="account-type-option">
              <input
                type="radio"
                name="accountType"
                value="personal"
                checked={accountType === 'personal'}
                onChange={handleAccountTypeChange}
              />
              <span>Personal Account - Occasional seller</span>
            </label>
            <label className="account-type-option">
              <input
                type="radio"
                name="accountType"
                value="business"
                checked={accountType === 'business'}
                onChange={handleAccountTypeChange}
              />
              <span>Business Account - Registered business</span>
            </label>
          </div>

          <form onSubmit={handleFormSubmit} className="activation-form">
            <div className="form-section">
              <h2>Personal Information</h2>
              <div className="form-group">
                <label>Full Name</label>
                <input type="text" name="fullName" value={userData.fullName} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" name="contactEmail" value={userData.contactEmail} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <input type="tel" name="contactPhone" value={userData.contactPhone} onChange={handleInputChange} required />
              </div>
            </div>

            {accountType === 'business' && (
              <>
                <div className="form-section">
                  <h2>Business Information</h2>
                  <div className="form-group">
                    <label>Business Name</label>
                    <input type="text" name="businessName" value={userData.businessName} onChange={handleInputChange} required />
                  </div>
                  <div className="form-group">
                    <label>Registration Number</label>
                    <input
                      type="text"
                      name="registrationNumber"
                      value={userData.registrationNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Tax ID</label>
                    <input type="text" name="taxId" value={userData.taxId} onChange={handleInputChange} />
                  </div>
                </div>

                <div className="form-section">
                  <h2>Required Documents</h2>

                  <div className="form-group">
                    <label>Proof of ID</label>
                    <div className="file-input-wrapper">
                      <input
                        type="file"
                        id="proofOfId"
                        className="file-input"
                        onChange={handleProofOfIdChange}
                        required
                      />
                      <label htmlFor="proofOfId" className="file-input-label">
                        <FaRegFileAlt className="file-icon" />
                        {proofOfId ? proofOfId.name : 'Choose a file'}
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Business Registration Certificate</label>
                    <div className="file-input-wrapper">
                      <input
                        type="file"
                        id="registrationCertificate"
                        className="file-input"
                        onChange={handleRegistrationCertificateChange}
                        required
                      />
                      <label htmlFor="registrationCertificate" className="file-input-label">
                        <FaRegFileAlt className="file-icon" />
                        {registrationCertificate ? registrationCertificate.name : 'Choose a file'}
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Tax Certificate</label>
                    <div className="file-input-wrapper">
                      <input
                        type="file"
                        id="taxCertificate"
                        className="file-input"
                        onChange={handleTaxCertificateChange}
                        required
                      />
                      <label htmlFor="taxCertificate" className="file-input-label">
                        <FaRegFileAlt className="file-icon" />
                        {taxCertificate ? taxCertificate.name : 'Choose a file'}
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Address Verification</label>
                    <div className="file-input-wrapper">
                      <input
                        type="file"
                        id="addressVerification"
                        className="file-input"
                        onChange={handleAddressVerificationChange}
                        required
                      />
                      <label htmlFor="addressVerification" className="file-input-label">
                        <FaRegFileAlt className="file-icon" />
                        {addressVerification ? addressVerification.name : 'Choose a file'}
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            {accountType === 'personal' && (
              <div className="form-section">
                <h2>Required Document</h2>
                <p>Please upload a government-issued ID for personal verification and proof of address.</p>
                <div className="form-group">
                  <label>Proof of ID and Address</label>
                  <div className="file-input-wrapper">
                    <input
                      type="file"
                      id="proofOfIdPersonal"
                      className="file-input"
                      onChange={handleProofOfIdChange}
                      required
                    />
                    <label htmlFor="proofOfIdPersonal" className="file-input-label">
                      <FaRegFileAlt className="file-icon" />
                      {proofOfId ? proofOfId.name : 'Choose a file'}
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="terms-and-conditions">
              <label className="terms-checkbox">
                <input type="checkbox" checked={agreed} onChange={handleCheckboxChange} required />
                <span>
                  I have read and agree to the{' '}
                  <Link to="/terms-and-conditions" className="terms-link" target="_blank">
                    Business Account Terms and Conditions
                  </Link>
                </span>
              </label>
            </div>

            <button type="submit" disabled={!agreed || loading} className="activate-button">
              {loading ? (
                <>
                  <FaSpinner className="spinner-icon" />
                  Activating...
                </>
              ) : (
                <>
                  <FaCheckCircle className="button-icon" />
                  Activate Account
                </>
              )}
            </button>
          </form>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default ActivateBusinessAccount;

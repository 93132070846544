import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../style/Admin/Payment/PaymentDetails.css';
import { ToastContainer, toast } from 'react-toastify';
import { jwtDecode } from "jwt-decode";

interface Payment {
  id: number;
  userId: number;
  interimCheckoutPart2Id: number;
  paymentReference: string;
  totalPrice: number;
  shippingPrice: number;
  createdAt: string;
  status: string;
  paymentMethod: string;
  notes: string;
  proofOfPayment: string;
  proofOfPaymentType: string;
  lastUpdatedBy: number;
  lastUpdatedComments: string;
  lastUpdatedAt: string;
}

interface DecodedToken {
    userId: number;
    role: string;
    [key: string]: any;
  }

const PaymentDetails: React.FC = () => {
  // Hooks at the top level
  const { paymentId } = useParams<{ paymentId: string }>();
  const [payment, setPayment] = useState<Payment | null>(null);
  const navigate = useNavigate();

  // Extract isAdmin from JWT token
// Extract admin access from JWT token
const token = localStorage.getItem('jwtToken');
let isAdmin = false;

if (token) {
  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    isAdmin = decodedToken.role === "Admin"; // Check the role field for Admin access
  } catch (error) {
    console.error('Failed to decode token:', error);
  }
} else {
  toast.error('Authentication token not found. Please log in.');
  navigate('/login');
}

// Handle unauthorized access
useEffect(() => {
  if (!isAdmin) {
    toast.error('You do not have permission to access this page.');
    navigate('/not-authorized');
  }
}, [isAdmin, navigate]);

// Fetch payment details
useEffect(() => {
  const fetchPaymentDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/${paymentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPayment(response.data);
    } catch (error) {
      console.error('Error fetching payment details:', error);
      toast.error('Failed to fetch payment details.');
    }
  };

  if (paymentId && isAdmin) {
    fetchPaymentDetails();
  }
}, [paymentId, isAdmin, token]);


  // Fetch payment details
  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/Payment/${paymentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPayment(response.data);
      } catch (error) {
        console.error('Error fetching payment details:', error);
        toast.error('Failed to fetch payment details.');
      }
    };

    if (paymentId && isAdmin) {
      fetchPaymentDetails();
    }
  }, [paymentId, isAdmin, token]);

  // Conditional rendering after Hooks
  if (!isAdmin) {
    return null; // Prevent rendering if not admin
  }

  if (!payment) {
    return (
      <div className="payment-details-loading">
        <h1>Payment Details</h1>
        <p>Loading...</p>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="payment-details-page">
      <h1>Payment Details</h1>
      <div className="payment-details">
        <p>
          <strong>Payment ID:</strong> {payment.id}
        </p>
        <p>
          <strong>User ID:</strong> {payment.userId}
        </p>
        <p>
          <strong>Payment Reference:</strong> {payment.paymentReference}
        </p>
        <p>
          <strong>Total Price:</strong> R{payment.totalPrice.toFixed(2)}
        </p>
        <p>
          <strong>Shipping Price:</strong> R{payment.shippingPrice.toFixed(2)}
        </p>
        <p>
          <strong>Created At:</strong>{' '}
          {new Date(payment.createdAt).toLocaleString()}
        </p>
        <p>
          <strong>Status:</strong> {payment.status}
        </p>
        <p>
          <strong>Payment Method:</strong> {payment.paymentMethod}
        </p>
        <p>
          <strong>Notes:</strong> {payment.notes || 'N/A'}
        </p>
        <p>
          <strong>Proof of Payment:</strong>{' '}
          {payment.proofOfPayment ? (
            <a
              href={payment.proofOfPayment}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Proof of Payment
            </a>
          ) : (
            'N/A'
          )}
        </p>
        <p>
          <strong>Proof of Payment Type:</strong>{' '}
          {payment.proofOfPaymentType || 'N/A'}
        </p>
        <p>
          <strong>Last Updated By:</strong>{' '}
          {payment.lastUpdatedBy || 'N/A'}
        </p>
        <p>
          <strong>Last Updated Comments:</strong>{' '}
          {payment.lastUpdatedComments || 'N/A'}
        </p>
        <p>
          <strong>Last Updated At:</strong>{' '}
          {payment.lastUpdatedAt
            ? new Date(payment.lastUpdatedAt).toLocaleString()
            : 'N/A'}
        </p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentDetails;

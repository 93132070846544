import React from 'react';
import '../../style/Header and Footer/Footer.css';
import { Link } from 'react-router-dom'; // Import Link for navigation

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h4>About Us</h4>
          <p>
            Book Shop offers a wide range of books from different categories. Find your next great read today!
          </p>
        </div>
        <div className="footer-section links">
          <h4>Quick Links</h4>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/categories">Categories</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h4>Contact Us</h4>
          <p>Email: thesecondhandbookdealer@gmail.com</p>
          <p>Phone: +27 766 427 807</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} The Second Hand Book Dealer. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/Business/ManageShipping.css';
import { jwtDecode } from 'jwt-decode';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';

interface ShippingMethod {
  id: number;
  shippingMethodName: string;
  description: string;
  price: number;
  estimatedDeliveryTime: string;
}

interface DecodedToken {
  userId: string;
  isBusinessActivated: string;
  [key: string]: any;
}

const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const ManageShipping: React.FC = () => {
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);
  const [filteredShippingMethods, setFilteredShippingMethods] = useState<ShippingMethod[]>([]);
  const [savedMethods, setSavedMethods] = useState<ShippingMethod[]>([]);
  const [selectedMethods, setSelectedMethods] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = getUserIdFromToken();

  useEffect(() => {
    const fetchShippingMethods = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Shipping/GetAllShippingMethods`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setShippingMethods(response.data);
        setFilteredShippingMethods(response.data);
      } catch (error) {
        toast.error('Failed to fetch shipping methods');
        console.error(error);
      }
    };

    const fetchSavedShippingMethods = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Shipping/SavedShippingMethods/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setSavedMethods(response.data);
      } catch (error) {
        toast.error('Failed to fetch saved shipping methods');
        console.error(error);
      }
    };

    fetchShippingMethods();
    if (userId) fetchSavedShippingMethods();
  }, [userId]);

  useEffect(() => {
    const filtered = shippingMethods.filter(method =>
      method.shippingMethodName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredShippingMethods(filtered);
  }, [searchQuery, shippingMethods]);

  const handleSelectionChange = (id: number) => {
    setSelectedMethods(prevSelected =>
      prevSelected.includes(id) ? prevSelected.filter(methodId => methodId !== id) : [...prevSelected, id]
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Shipping/SaveBusinessShippingMethods`,
        { userId, selectedMethodIds: selectedMethods },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        }
      );

      if (response.status === 200) {
        toast.success('Shipping methods saved successfully!');
        setSavedMethods(prevMethods => [
          ...prevMethods,
          ...shippingMethods.filter(method => selectedMethods.includes(method.id))
        ]);
        setSelectedMethods([]); // Clear selected methods after saving
      }
    } catch (error) {
      toast.error('Failed to save selected shipping methods');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSavedMethod = async (methodId: number) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/Shipping/DeleteBusinessShippingMethod/${methodId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      setSavedMethods(prevMethods => prevMethods.filter(method => method.id !== methodId));
      toast.success('Shipping method deleted successfully!');
    } catch (error) {
      toast.error('Failed to delete shipping method');
      console.error(error);
    }
  };

  return (
    <div className="manage-shipping-page">
      <Header />
      <div className="manage-shipping-content">
        <div className="main-content">
          <h1>Select Shipping Methods</h1>
          <input
            type="text"
            placeholder="Search shipping methods..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <div className="shipping-methods-list">
            {filteredShippingMethods.map((method) => (
              <div key={method.id} className="shipping-method-item">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedMethods.includes(method.id)}
                    onChange={() => handleSelectionChange(method.id)}
                  />
                  <div className="shipping-method-details">
                    <h3>{method.shippingMethodName}</h3>
                    <p>{method.description}</p>
                    <p>Price: {method.price} ZAR</p>
                    <p>Estimated Delivery: {method.estimatedDeliveryTime}</p>
                  </div>
                </label>
              </div>
            ))}
          </div>
          <button
            className="save-button"
            onClick={handleSubmit}
            disabled={loading || selectedMethods.length === 0}
          >
            {loading ? 'Saving...' : 'Save Selected Methods'}
          </button>
          <ToastContainer position="top-right" autoClose={5000} />
        </div>
        <div className="right-sidebar">
          <h2>Saved Shipping Methods</h2>
          <ul className="saved-methods-list">
            {savedMethods.map((method) => (
              <li key={method.id} className="saved-method-item">
                <h4>{method.shippingMethodName}</h4>
                <p>Price: {method.price} ZAR</p>
                <p>Estimated Delivery: {method.estimatedDeliveryTime}</p>
                <button onClick={() => handleDeleteSavedMethod(method.id)} className="delete-button">
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ManageShipping;

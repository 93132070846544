// src/pages/Statistics.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie,
  Tooltip, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer,
  Cell,
} from 'recharts';
import { Grid, Typography, Paper, CircularProgress, Box } from '@mui/material';
import Header from '../components/Header and Footer/Header';
import Footer from '../components/Header and Footer/Footer';

const Statistics: React.FC = () => {
  const [loginsByTime, setLoginsByTime] = useState([]);
  const [loginsPerDay, setLoginsPerDay] = useState([]);
  const [topActiveUsers, setTopActiveUsers] = useState([]);
  const [loginsByCountry, setLoginsByCountry] = useState([]);
  const [loginsByCity, setLoginsByCity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#845EC2', '#D65DB1', '#FF6F91', '#FFC75F'];


  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const [
          loginsByTimeResponse,
          loginsPerDayResponse,
          topActiveUsersResponse,
          loginsByCountryResponse,
          loginsByCityResponse,
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Admin/geolocation-summary/times`),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Admin/reports/logins-per-day`),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Admin/reports/top-active-users`, { params: { topN: 30 } }),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Admin/geolocation-summary/countries`),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Admin/logins-by-city`),
        ]);

        setLoginsByTime(loginsByTimeResponse.data);
        setLoginsPerDay(loginsPerDayResponse.data);
        setTopActiveUsers(topActiveUsersResponse.data);
        setLoginsByCountry(loginsByCountryResponse.data);
        setLoginsByCity(loginsByCityResponse.data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  if (isLoading) {
    return (
      <>
        <Header />
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <CircularProgress />
        </Box>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <Box sx={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Statistics Dashboard
        </Typography>
        <Grid container spacing={4}>
          {/* Logins by Time */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: '20px' }}>
              <Typography variant="h6" gutterBottom>
                Logins by Time
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={loginsByTime}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="loginCount" stroke="#3f51b5" />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Logins Per Day */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: '20px' }}>
              <Typography variant="h6" gutterBottom>
                Logins Per Day
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={loginsPerDay}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="totalLogins" name="Total Logins" fill="#3f51b5" />
                  <Bar dataKey="uniqueUsers" name="Unique Users" fill="#f50057" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Top Active Users */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: '20px' }}>
              <Typography variant="h6" gutterBottom>
                Top Active Users
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={topActiveUsers} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" allowDecimals={false} />
                  <YAxis dataKey="userId" type="category" width={100} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="loginCount" name="Login Count" fill="#3f51b5" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Logins by City */}
          <Grid item xs={12} md={6}>
  <Paper elevation={3} sx={{ padding: '20px' }}>
    <Typography variant="h6" gutterBottom>
      Logins by City
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Tooltip />
        <Legend layout="vertical" align="right" verticalAlign="middle" />
        <Pie
          data={loginsByCity}
          dataKey="totalLogins"
          nameKey="city"
          cx="50%"
          cy="50%"
          outerRadius={120}
          labelLine={false}
          label={({ city, percent }) => `${city}: ${(percent * 100).toFixed(0)}%`}
        >
          {loginsByCity.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  </Paper>
</Grid>


        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Statistics;

// src/components/Payment/PaymentPage.tsx
import React, { useEffect, useState, ChangeEvent } from 'react';
import axios from 'axios';
import { FaCreditCard, FaLock, FaSpinner, FaCheckCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Payment/PaymentPage.css';
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  userId: string;
  [key: string]: any;
}

// Function to get userId from the JWT token
const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const PaymentPage: React.FC = () => {
  const [totalPrice, setTotalPrice] = useState<number | null>(null);
  const [paymentReference, setPaymentReference] = useState<string | null>(null);
  const [proofOfPayment, setProofOfPayment] = useState<File | null>(null);
  const [noteText, setNoteText] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);

  const userId = getUserIdFromToken();
  const interimCheckoutPart2Id = parseInt(localStorage.getItem('interimCheckoutPart2Id') || '0');

  useEffect(() => {
    if (userId && interimCheckoutPart2Id) {
      fetchTotalPrice();
      fetchPaymentReference();
    } else {
      console.error('User ID or InterimCheckoutPart2Id is missing.');
    }
  }, [userId, interimCheckoutPart2Id]);

  const fetchTotalPrice = async () => {
    setIsFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/total-price-by-user?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );

      setTotalPrice(response.data.totalPrice);
      console.log('Total Price:', response.data.totalPrice);
    } catch (error) {
      console.error('Error fetching total price:', error);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchPaymentReference = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/payment-reference?userId=${userId}&interimCheckoutPart2Id=${interimCheckoutPart2Id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      setPaymentReference(response.data.paymentReference);
    } catch (error) {
      console.error('Error fetching payment reference:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setProofOfPayment(event.target.files[0]);
    } else {
      setProofOfPayment(null);
    }
  };

  const handleProofUpload = async () => {
    if (!proofOfPayment) {
      toast.error('Please select a proof of payment file before uploading.');
      return;
    }

    const paymentId = localStorage.getItem('paymentId');
    if (!paymentId) {
      toast.error('No payment ID found. Please complete payment first.');
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    formData.append('proofOfPayment', proofOfPayment);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/upload-proof/${paymentId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success('Proof of payment uploaded successfully.');
      setProofOfPayment(null);
    } catch (error) {
      console.error('Error uploading proof of payment:', error);
      toast.error('Failed to upload proof of payment. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteText(event.target.value);
  };

  const handleAddNote = async () => {
    const paymentId = localStorage.getItem('paymentId');
    if (!paymentId) {
      toast.error('No payment ID found. Please complete payment first.');
      return;
    }
  
    if (noteText.trim() === '') {
      toast.error('Please enter a note before adding.');
      return;
    }
  
    setIsAddingNote(true);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/Payment/payments/${paymentId}/add-note`, JSON.stringify(noteText), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        toast.success(response.data.message || 'Note added successfully!');
        setNoteText('');
      } else {
        toast.error('Failed to add note. Please try again.');
      }
    } catch (error) {
      console.error('Error adding note:', error);
      toast.error('There was an error adding your note.');
    } finally {
      setIsAddingNote(false);
    }
  };
  
  
  
  return (
    <>
      <Header />
      <div className="payment-page-container">
        <div className="payment-content-wrapper">
          <h1 className="page-title">Secure Checkout</h1>

          {/* Important Notice */}
    <div className="important-note-card">
      <p className="important-note">
        <strong>Important:</strong> You do not need to make the payment immediately. You will have 48 hours to complete the payment and upload proof of payment. After this time, your order will be canceled if payment is not received. To complete your payment later, go to your profile section and navigate to the "Pending Payments" section. 
        <br /><br />
        If you selected "PEP," "PARGO," or "PUDO" as a shipping method, please include your preferred store or mall for delivery in the notes section.
      </p>
    </div>

          {isFetching ? (
            <div className="loading-spinner">
              <FaSpinner className="spinner-icon" />
              <p>Loading payment details...</p>
            </div>
          ) : (
            <>
              {/* Order Summary */}
              <div className="order-summary-card">
                <h2>Order Summary</h2>
                <div className="summary-details">
                  <div className="summary-item">
                    <span className="detail-label">Total:</span>
                    <span className="detail-value">
                      R {totalPrice ? totalPrice.toFixed(2) : 'N/A'}
                    </span>
                  </div>
                </div>
                <p className="payment-instructions">
                  Please send the amount to the following account.
                </p>
              </div>

{/* Bank Details */}
<div className="bank-details-card">
  <h2>Bank Details</h2>
  <div className="bank-details-content">
    <p>
      <strong>Account Name:</strong> The Second Hand Book Dealer (Pty) Ltd
    </p>
    <p>
      <strong>Bank:</strong> Nedbank Limited
    </p>
    <p>
      <strong>Account Type:</strong> Current Account
    </p>
    <p>
      <strong>Account Number:</strong> 1275278124
    </p>
    <p>
      <strong>Branch Code:</strong> 198765
    </p>
  </div>
</div>


              {/* Payment Reference */}
              <div className="payment-reference-card">
                <h2>Payment Reference</h2>
                <p className="payment-reference">
                  {paymentReference || 'Loading...'}
                </p>
              </div>

              {/* Note Section */}
              <div className="note-section-card">
                <h2>Add a Note</h2>
                <textarea
                  id="note"
                  placeholder="Add any special delivery or payment instructions..."
                  value={noteText}
                  onChange={handleNoteChange}
                />
                <button
                  type="button"
                  className="submit-button"
                  onClick={handleAddNote}
                  disabled={isAddingNote}
                >
                  {isAddingNote ? (
                    <>
                      <FaSpinner className="spinner-icon" />
                      Adding Note...
                    </>
                  ) : (
                    <>
                      <FaCheckCircle className="button-icon" />
                      Add Note
                    </>
                  )}
                </button>
              </div>

              {/* Proof of Payment */}
              <div className="proof-of-payment-card">
                <h2>
                  <FaCreditCard className="icon" /> Upload Proof of Payment
                </h2>
                <p>Please upload your proof of payment to expedite processing.</p>
                <div className="file-input-wrapper">
                  <input
                    type="file"
                    id="proofOfPayment"
                    className="file-input"
                    onChange={handleFileChange}
                    accept="image/*,.pdf"
                  />
                  <label htmlFor="proofOfPayment" className="file-input-label">
                    {proofOfPayment ? proofOfPayment.name : 'Choose a file'}
                  </label>
                </div>
                <button
                  type="button"
                  className="submit-button"
                  onClick={handleProofUpload}
                  disabled={isUploading || !proofOfPayment}
                >
                  {isUploading ? (
                    <>
                      <FaSpinner className="spinner-icon" />
                      Uploading...
                    </>
                  ) : (
                    <>
                      <FaLock className="button-icon" />
                      Upload Proof of Payment
                    </>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default PaymentPage;

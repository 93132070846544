// src/components/Orders/MyOrders.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../style/Orders/MyOrders.css'; // Import the CSS file
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import { jwtDecode } from "jwt-decode";

interface Product {
  id: number;
  name: string;
  author: string;
  price: number;
  isbn: string;
  description: string;
  imageUrl: string;
  imageData: string | null;
  genre: string;
}

interface OrderDetail {
  id: number;
  orderId: number;
  productId: number;
  quantity: number;
  price: number;
  product: Product;
}

interface Order {
  id: number;
  userId: number;
  orderDate: string;
  totalAmount: number;
  user: null;
  orderDetails: OrderDetail[];
}

interface DecodedToken {
  userId: string;
  [key: string]: any;
}

// Function to get userId from the JWT token
const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const MyOrders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const userId = getUserIdFromToken();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (!userId) {
          throw new Error('User is not logged in.');
        }

        const response = await axios.get<Order[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/Orders/user-orders/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            },
          }
        );
        setOrders(response.data);
        setLoading(false);
      } catch (err: any) {
        console.error('Error fetching orders:', err);
        setError('Oops, you have not placed any orders');
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId]);

  // Function to handle logging a dispute
  const handleLogDispute = (orderId: number) => {
    console.log(`Logging a dispute for order ID: ${orderId}`);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading orders...</p>
      </div>
    );
  }

  if (error)
    return (
      <>
        <Header />
        <div className="error">{error}</div>
        <Footer />
      </>
    );

  return (
    <>
      <Header />
      <div className="my-orders">
        <h1>My Orders</h1>
        {orders.map((order) => (
          <div key={order.id} className="order-card">
            <div className="order-header">
              <h2>Order #{order.id}</h2>
              <button
                className="log-dispute-button"
                onClick={() => handleLogDispute(order.id)}
              >
                Log a Dispute
              </button>
              <p>
                Date: {new Date(order.orderDate).toLocaleDateString()} | Total:{' '}
                <strong>R {order.totalAmount.toFixed(2)}</strong>
              </p>
            </div>
            <div className="order-details">
              {order.orderDetails.map((detail) => (
                <div key={detail.id} className="order-item">
                  <img
                    src={
                      detail.product.imageUrl.startsWith('/')
                        ? `${process.env.REACT_APP_API_BASE_URL}${detail.product.imageUrl}`
                        : detail.product.imageUrl
                    }
                    alt={detail.product.name}
                    className="product-image"
                    onError={(e) => {
                      e.currentTarget.src =
                        'https://via.placeholder.com/120x180?text=No+Image';
                    }}
                  />
                  <div className="product-info">
                    <h3>{detail.product.name}</h3>
                    <p>by {detail.product.author}</p>
                    <p>
                      Quantity: {detail.quantity} | Price per item: R{' '}
                      {detail.price.toFixed(2)}
                    </p>
                    <p>
                      <strong>
                        Subtotal: R{' '}
                        {(detail.quantity * detail.price).toFixed(2)}
                      </strong>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default MyOrders;

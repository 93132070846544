// src/components/Admin/Admin.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Admin/Admin.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Users,
  CreditCard,
  ListChecks,
  Shield,
  Truck,
  Megaphone,
  BarChart,
  Speaker
} from 'lucide-react';
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  userId: number;
  role: string;
  [key: string]: any;
}

const Admin: React.FC = () => {
  const navigate = useNavigate();

  // Extract isAdmin from JWT token
// Extract admin access from JWT token
const token = localStorage.getItem('jwtToken');
let isAdmin = false;

if (token) {
  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    isAdmin = decodedToken.role === "Admin"; // Check the role field for Admin access
  } catch (error) {
    console.error('Failed to decode token:', error);
    navigate('/login');
  }
} else {
  navigate('/login');
}

// Handle unauthorized access inside useEffect
useEffect(() => {
  if (!isAdmin) {
    toast.error('You do not have permission to access this page.');
    navigate('/not-authorized');
  }
}, [isAdmin, navigate]);

// Conditional rendering after Hooks
if (!isAdmin) {
  return null; // Explicitly return null
}


  const handleCardClick = (action: string) => {
    switch (action) {
      case 'Manage Users':
        navigate('/manage-users');
        break;
      case 'Process payments':
        navigate('/process-user-payments');
        break;
      case 'Process orders':
        navigate('/process-order');
        break;
      case 'Get Original Price':
        navigate('/get-original-price');
        break;
      case 'User Permissions':
        navigate('/user-permissions');
        break;
      case 'Manage Ads':
        navigate('/manage-ads');
        break;
      case 'Add Shipping Methods':
        navigate('/shipping-management');
        break;
      case 'View Statistics':
        navigate('/statistics');
        break;
        case 'Send Announcement':
        navigate('/announce-details');
        break;
      default:
        console.log(`No action defined for: ${action}`);
    }
  };

  const adminCards = [
    {
      title: 'Manage Users',
      action: 'Manage Users',
      icon: <Users size={48} />,
      description: 'View and manage user accounts.',
    },
    {
      title: 'Process Payments',
      action: 'Process payments',
      icon: <CreditCard size={48} />,
      description: 'Handle user payment transactions.',
    },
    {
      title: 'Process Orders',
      action: 'Process orders',
      icon: <ListChecks size={48} />,
      description: 'Manage and fulfill orders.',
    },
    {
      title: 'User Permissions',
      action: 'User Permissions',
      icon: <Shield size={48} />,
      description: 'Control user access levels.',
    },
    {
      title: 'Add Shipping Methods',
      action: 'Add Shipping Methods',
      icon: <Truck size={48} />,
      description: 'Define and manage shipping options.',
    },
    {
      title: 'Manage Ads',
      action: 'Manage Ads',
      icon: <Megaphone size={48} />,
      description: 'Create and manage advertisements.',
    },
    {
      title: 'Get Original Price',
      action: 'Get Original Price',
      icon: <CreditCard size={48} />,
      description: 'Calculate the original price from a marked-up price.',
    },
    {
      title: 'Statistics',
      action: 'View Statistics',
      icon: <BarChart size={48} />,
      description: 'View detailed site statistics and reports.',
    },
    {
      title: 'Send Announcement',
      action: 'Send Announcement',
      icon: <Speaker size={48} />,
      description: 'Send announcements to all active users.',
    },
  ];

  return (
    <>
      <Header />
      <div className="admin-dashboard-container">
        <ToastContainer />
        <div className="admin-dashboard-content">
          <h1 className="admin-dashboard-title">Admin Dashboard</h1>
          <div className="dashboard-cards">
            {adminCards.map((card, index) => (
              <div
                key={index}
                className="dashboard-card"
                onClick={() => handleCardClick(card.action)}
              >
                <div className="card-icon">{card.icon}</div>
                <h3 className="card-title">{card.title}</h3>
                <p className="card-description">{card.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Admin;

// src/components/Business/SubmitRequestMessage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import '../../../style/Business/SubmitRequestMessage.css';

const SubmitRequestMessage: React.FC = () => {
  return (
    <div className="submit-request-message-container">
      <div className="message-box">
        <FaCheckCircle className="icon" />
        <h1>Request Submitted!</h1>
        <p>Your account activation request has been successfully submitted. Our team will review your documents and get back to you shortly.</p>
        <Link to="/profile" className="back-to-profile-button">
          Go Back to Profile
        </Link>
      </div>
    </div>
  );
};

export default SubmitRequestMessage;

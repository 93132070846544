// src/components/Admin/Ads/ManageAds.tsx

import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../Header and Footer/Header';
import Footer from '../../Header and Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import '../../../style/Admin/Ads/ManageAds.css';
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  userId: number;
  role: string;
  [key: string]: any;
}

const ManageAds: React.FC = () => {
  // **Hooks at the Top Level**
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [image, setImage] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const navigate = useNavigate();

  // **Extract isAdmin from JWT token**
// **Extract admin access from JWT token**
const token = localStorage.getItem('jwtToken');
let isAdmin = false;

if (token) {
  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    isAdmin = decodedToken.role === "Admin"; // Check the role field for Admin access
  } catch (error) {
    console.error('Failed to decode token:', error);
  }
} else {
  navigate('/login');
}

// **Handle unauthorized access inside useEffect**
useEffect(() => {
  if (!isAdmin) {
    toast.error('You do not have permission to access this page.');
    navigate('/not-authorized');
  }
}, [isAdmin, navigate]);

// **Conditional Rendering After Hooks**
if (!isAdmin) {
  return null; // Explicitly return null instead of undefined
}


  // Handle file input change
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!image) {
      toast.error('Please select an image to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', image);

    try {
      setIsUploading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Ads`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success('Ad uploaded successfully!');
        setTitle('');
        setDescription('');
        setImage(null);
      }
    } catch (error) {
      console.error('Error uploading ad:', error);
      toast.error('Failed to upload ad. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="manage-ads-container">
      <Header />
      <div className="manage-ads-content">
        <h2>Manage Ads</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Ad Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter ad title"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Ad Description</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter ad description"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image">Ad Image</label>
            <input
              type="file"
              id="image"
              onChange={handleFileChange}
              accept="image/*"
              required
            />
          </div>
          <button type="submit" className="upload-button" disabled={isUploading}>
            {isUploading ? 'Uploading...' : 'Upload Ad'}
          </button>
        </form>
        <ToastContainer />
      </div>
      <Footer />
    </div>
  );
};

export default ManageAds;

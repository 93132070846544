// src/components/Wishlist/Wishlist.tsx

import React, { useState, useEffect } from 'react';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Wishlist/Wishlist.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from "jwt-decode";

interface Product {
  id: number;
  name: string;
  author: string;
  price: number;
  isbn: string;
  description: string;
  imageUrl: string;
  genre: string;
}

interface WishlistItem {
  id: number;
  productId: number;
  product: Product;
}

interface DecodedToken {
  userId: string;
  [key: string]: any;
}

// Function to get userId from the JWT token
const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const Wishlist: React.FC = () => {
  const [wishlistItems, setWishlistItems] = useState<WishlistItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchWishlistItems = async () => {
    const userId = getUserIdFromToken(); // Retrieve userId from the JWT
    if (!userId) {
      setError('User not logged in');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/Wishlist/items?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch wishlist items');
      }
      const items: WishlistItem[] = await response.json();
      setWishlistItems(items);
    } catch (error) {
      console.error('Error fetching wishlist items:', error);
      setError('Failed to load wishlist items');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFromWishlist = async (itemId: number) => {
    const userId = getUserIdFromToken();
    if (!userId) {
      toast.error('User not logged in');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/Wishlist/remove/${itemId}?userId=${userId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to remove wishlist item');
      }

      setWishlistItems((prevItems) => prevItems.filter((item) => item.id !== itemId));

      // Display a success toast
      toast.success('Item removed from wishlist successfully!');
    } catch (error) {
      console.error('Error removing wishlist item:', error);
      toast.error('Failed to remove item from wishlist');
    }
  };

  const handleMoveToCart = async (itemId: number) => {
    const userId = getUserIdFromToken();
    if (!userId) {
      toast.error('User not logged in');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/Wishlist/move-to-cart/${itemId}?userId=${userId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to move item to cart');
      }

      setWishlistItems((prevItems) => prevItems.filter((item) => item.id !== itemId));

      // Display a success toast
      toast.success('Item moved to cart successfully!');
    } catch (error) {
      console.error('Error moving item to cart:', error);
      toast.error('Failed to move item to cart');
    }
  };

  useEffect(() => {
    fetchWishlistItems();
  }, []);

  if (loading) {
    return (
      <>
        <Header />
        <div className="wishlist-loading-container">
          <div className="wishlist-loading-spinner"></div>
          <p>Loading Wishlist...</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <div className="wishlist-page-container">
      <Header />
      <div className="wishlist-page">
        <ToastContainer />
        {error && <p className="wishlist-error-message">{error}</p>}

        <div className="wishlist-content">
          <h1 className="wishlist-title">Your Wishlist</h1>
          {wishlistItems.length === 0 ? (
            <p className="wishlist-empty-message">Your wishlist is empty.</p>
          ) : (
            <ul className="wishlist-items-list">
              {wishlistItems.map((item) => (
                <li key={item.id} className="wishlist-item">
                  <div className="wishlist-item-image-container">
                    <img
                      src={
                        item.product.imageUrl.startsWith('/')
                          ? `${process.env.REACT_APP_API_BASE_URL}${item.product.imageUrl}`
                          : item.product.imageUrl
                      }
                      alt={item.product.name}
                      className="wishlist-item-image"
                      onError={(e) => {
                        e.currentTarget.src =
                          'https://via.placeholder.com/120x180?text=No+Image';
                        e.currentTarget.alt = 'Placeholder image';
                      }}
                    />
                  </div>
                  <div className="wishlist-item-info">
                    <h4 className="wishlist-item-name">{item.product.name}</h4>
                    <p className="wishlist-item-author">Author: {item.product.author}</p>
                    <p className="wishlist-item-price">
                      Price: R {item.product.price.toFixed(2)}
                    </p>
                    <p className="wishlist-item-isbn">ISBN: {item.product.isbn}</p>
                    <p className="wishlist-item-description">{item.product.description}</p>
                    <p className="wishlist-item-genre">Genre: {item.product.genre}</p>
                  </div>
                  <div className="wishlist-item-actions">
                    <button
                      onClick={() => handleRemoveFromWishlist(item.id)}
                      className="wishlist-item-action-button remove-button"
                    >
                      <FontAwesomeIcon icon={faTrash} /> Remove
                    </button>
                    <button
                      onClick={() => handleMoveToCart(item.id)}
                      className="wishlist-item-action-button move-button"
                    >
                      <FontAwesomeIcon icon={faShoppingCart} /> Move to Cart
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Wishlist;

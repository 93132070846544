import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Business/BusinessDashboard.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  ShoppingCart,
  BarChart2,
  Truck,
  Tag,
} from 'lucide-react';

interface BusinessInfo {
  businessName: string;
  totalSales: number;
  activeCustomers: number;
  recentOrders: string[];
}

const BusinessDashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [businessInfo, setBusinessInfo] = useState<BusinessInfo | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBusinessData();
  }, []);

  const fetchBusinessData = async () => {
    try {
      setLoading(true);
      // Replace this with your actual API call
      const data: BusinessInfo = await new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              businessName: 'My Business',
              totalSales: 1000,
              activeCustomers: 150,
              recentOrders: ['Order 1', 'Order 2', 'Order 3'],
            }),
          1000
        )
      );
      setBusinessInfo(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching business data:', error);
      setLoading(false);
    }
  };

  const handleCardClick = (action: string) => {
    switch (action) {
      case 'Put a book on sale':
        navigate('/sell');
        break;
      case 'Active orders':
        navigate('/active-orders');
        break;
      case 'Previous orders':
        navigate('/order-history');
        break;
      case 'Manage Sales':
        navigate('/manage-sales');
        break;
      case 'View analytics':
        navigate('/analytics');
        break;
      case 'Order advanced analytics':
        navigate('/advanced-analytics');
        break;
      case 'Manage Inventory':
        navigate('/inventory');
        break;
      case 'Customer Feedback':
        navigate('/customer-feedback');
        break;
      case 'Messages':
        navigate('/messages');
        break;
      case 'Promotions and Discounts':
        navigate('/promotions');
        break;
      case 'Account Settings':
        navigate('/profile');
        break;
      case 'Support Center':
        navigate('/support');
        break;
      case 'Financial Reports':
        navigate('/financial-reports');
        break;
      case 'Notifications':
        navigate('/notifications');
        break;
      case 'Marketing Tools':
        navigate('/marketing-tools');
        break;
      case 'Shipping Management':
        navigate('/select-shipping');
        break;
      case 'Tax Information':
        navigate('/tax-info');
        break;
      case 'Return and Refund Requests':
        navigate('/returns-and-refunds');
        break;
      case 'Subscription Management':
        navigate('/subscriptions');
        break;
      case 'Analytics Overview':
        navigate('/analytics-overview');
        break;
      case 'Employee Management':
        navigate('/employee-management');
        break;
      case 'Supplier Management':
        navigate('/supplier-management');
        break;
      case 'Event Scheduling':
        navigate('/event-scheduling');
        break;
        case 'Batch Listing':
        navigate('/batch-sell');
         break;
      case 'Affiliate Programs':
        navigate('/affiliate-programs');
        break;
      default:
        console.log(`No redirection set for: ${action}`);
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="business-loading-container">
          <div className="business-loading-spinner"></div>
          <p>Loading ...</p>
        </div>
        <Footer />
      </>
    );
  }

  const cards = [
    { title: 'Put a book on sale', action: 'Put a book on sale', icon: <ShoppingCart size={32} /> }, 
    { title: 'Manage Sales', action: 'Manage Sales', icon: <BarChart2 size={32} /> },
    { title: 'Shipping Management', action: 'Shipping Management', icon: <Truck size={32} /> },
    { title: 'Batch Listing', action: 'Batch Listing', icon: <Tag size={32} /> },


    
   
  ];

  return (
    <>
      <Header />
      <div className="business-dashboard-page">
        <ToastContainer />
        <h1 className="business-dashboard-title">Welcome to Your Business Dashboard</h1>

        {businessInfo ? (
          <div className="business-dashboard-content">
          

            <div className="dashboard-cards">
              {cards.map((card, index) => (
                <div key={index} className="dashboard-card" onClick={() => handleCardClick(card.action)}>
                  <div className="dashboard-card-icon">{card.icon}</div>
                  <h3 className="dashboard-card-title">{card.title}</h3>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>No business data available.</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default BusinessDashboard;

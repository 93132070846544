import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/LandingPage/LandingPage.css';

interface Ad {
  id: number;
  imageUrl: string;
  title?: string;
  description?: string;
  linkUrl?: string;
}

const LandingPage: React.FC = () => {
  const [ad, setAd] = useState<Ad | null>(null);

  useEffect(() => {
    // Fetch the most recent ad from the backend
    const fetchAd = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/Ads/latest`
        );
        const adData = response.data;

        // Prefix the imageUrl with the server URL if it's a relative path
        if (adData.imageUrl && adData.imageUrl.startsWith('/')) {
          adData.imageUrl = `${process.env.REACT_APP_API_BASE_URL}${adData.imageUrl}`;
        }

        setAd(adData);
      } catch (error) {
        console.error('Error fetching ad:', error);
      }
    };

    fetchAd();
  }, []);

  return (
    <div className="landing-page">
      <Header />

      <main>
        <section className="hero">
          <div className="hero-content">
          <h1 style={{ color: 'white' }}>Welcome to Books Haven</h1>
          <p style={{ color: 'white' }}>Discover your next favorite read in our vast collection of books</p>
            <Link to="/categories" className="cta-button">
              Explore Categories
            </Link>
          </div>
        </section>

        <section className="features">
          <div className="feature">
            <i className="fas fa-book"></i>
            <h2>Vast Selection</h2>
            <p>Browse through thousands of titles across all genres</p>
          </div>
          <div className="feature">
            <i className="fas fa-truck"></i>
            <h2>Fast Delivery</h2>
            <p>Get your books delivered to your doorstep in no time</p>
          </div>
          <div className="feature">
            <i className="fas fa-user-friends"></i>
            <h2>Community</h2>
            <p>Join book clubs and discuss your favorite reads</p>
          </div>
        </section>

        <section className="ad-space">
          <div className="ad-container">
            {ad ? (
              <div className="ad-content">
                <p className="ad-label">Advertisement</p>
                {ad.linkUrl ? (
                  <a
                    href={ad.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ad-link"
                  >
                    {ad.imageUrl ? (
                      <img
                        src={ad.imageUrl}
                        alt={ad.title || 'Advertisement'}
                        className="ad-image"
                      />
                    ) : (
                      <div className="ad-placeholder">
                        <p>No image available</p>
                      </div>
                    )}
                  </a>
                ) : ad.imageUrl ? (
                  <img
                    src={ad.imageUrl}
                    alt={ad.title || 'Advertisement'}
                    className="ad-image"
                  />
                ) : (
                  <div className="ad-placeholder">
                    <p>No image available</p>
                  </div>
                )}
                {ad.title && <h3 className="ad-title">{ad.title}</h3>}
                {ad.description && <p className="ad-description">{ad.description}</p>}
              </div>
            ) : (
              <p className="ad-label">Advertisement</p>
            )}
          </div>
        </section>

        <section className="testimonials">
          <h2>What Our Customers Say</h2>
          <div className="testimonial">
            <p>
              "The Second Hand Book Dealer has an incredible selection. I always find what I'm
              looking for!"
            </p>
            <cite>- Jane D.</cite>
          </div>
        </section>

        <section className="newsletter">
          <h2>Stay Updated</h2>
          <p>Subscribe to our newsletter for the latest book releases and exclusive offers</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default LandingPage;

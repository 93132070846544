import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Category {
  name: string;
  description: string;
  genre: string;
  author: string;
  price: number;
}

interface CategoryWithFile {
  category: Category;
  file: File | null;
}

const MultiCategoryListing: React.FC = () => {
  const [categoriesWithFiles, setCategoriesWithFiles] = useState<CategoryWithFile[]>([]);

  const addCategory = () => {
    setCategoriesWithFiles([
      ...categoriesWithFiles,
      {
        category: { name: '', description: '', genre: '', author: '', price: 0 },
        file: null,
      },
    ]);
  };

  const handleCategoryChange = (
    index: number,
    field: keyof Category,
    value: string | number
  ) => {
    const updatedCategories = categoriesWithFiles.map((item, idx) =>
      idx === index
        ? { ...item, category: { ...item.category, [field]: value } }
        : item
    );
    setCategoriesWithFiles(updatedCategories);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0] || null;
    const updatedCategories = categoriesWithFiles.map((item, idx) =>
      idx === index ? { ...item, file } : item
    );
    setCategoriesWithFiles(updatedCategories);
  };

  const validateCategory = (category: Category): boolean => {
    if (!category.name.trim()) {
      toast.error('Please provide a valid name for the category.');
      return false;
    }
    if (!category.description.trim()) {
      toast.error('Please provide a valid description.');
      return false;
    }
    if (!category.genre.trim()) {
      toast.error('Please provide a valid genre.');
      return false;
    }
    if (!category.author.trim()) {
      toast.error('Please provide a valid author.');
      return false;
    }
    if (category.price <= 0) {
      toast.error('Please provide a valid price greater than 0.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (categoriesWithFiles.length === 0) {
      toast.error('No categories to submit.');
      return;
    }

    for (const { category, file } of categoriesWithFiles) {
      if (!validateCategory(category) || !file) {
        toast.error('Each category must be complete and have an image.');
        return;
      }
    }

    const formData = new FormData();
    categoriesWithFiles.forEach(({ category, file }, index) => {
      formData.append(`categories[${index}]`, JSON.stringify(category));
      if (file) {
        formData.append(`Image_${index}`, file);
      }
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Category/add-multiple-categories`,
        formData
      );
      toast.success('Categories added successfully!');
      setCategoriesWithFiles([]);
    } catch (error: any) {
      console.error('Failed to add categories:', error);
      toast.error(error.response?.data || 'Failed to add categories.');
    }
  };

  return (
    <div>
      <h1>Multi-Category Listing</h1>
      {categoriesWithFiles.map((item, index) => (
        <div key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
          <input
            type="text"
            placeholder="Name"
            value={item.category.name}
            onChange={(e) => handleCategoryChange(index, 'name', e.target.value)}
            style={{ display: 'block', marginBottom: '10px' }}
          />
          <textarea
            placeholder="Description"
            value={item.category.description}
            onChange={(e) => handleCategoryChange(index, 'description', e.target.value)}
            style={{ display: 'block', marginBottom: '10px' }}
          />
          <input
            type="text"
            placeholder="Genre"
            value={item.category.genre}
            onChange={(e) => handleCategoryChange(index, 'genre', e.target.value)}
            style={{ display: 'block', marginBottom: '10px' }}
          />
          <input
            type="text"
            placeholder="Author"
            value={item.category.author}
            onChange={(e) => handleCategoryChange(index, 'author', e.target.value)}
            style={{ display: 'block', marginBottom: '10px' }}
          />
          <input
            type="number"
            placeholder="Price"
            value={item.category.price}
            onChange={(e) =>
              handleCategoryChange(index, 'price', parseFloat(e.target.value))
            }
            style={{ display: 'block', marginBottom: '10px' }}
          />
          <input
            type="file"
            onChange={(e) => handleFileChange(e, index)}
            style={{ display: 'block', marginBottom: '20px' }}
          />
        </div>
      ))}
      <button onClick={addCategory} style={{ marginRight: '10px' }}>
        Add Category
      </button>
      <button onClick={handleSubmit}>Submit All Categories</button>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
};

export default MultiCategoryListing;

// src/components/Payment/ManagePayments.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaSpinner, FaExclamationCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Payment/ManagePayments.css';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

interface Payment {
  id: number;
  paymentReference: string;
  totalPrice: number;
  shippingPrice: number;
  createdAt: string;
  status: string;
}

interface DecodedToken {
  userId: string;
  [key: string]: any;
}

// Function to get userId from the JWT token
const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const ManagePayments: React.FC = () => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [timeRemaining, setTimeRemaining] = useState<{ [key: number]: number }>({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const userId = getUserIdFromToken();

  useEffect(() => {
    if (userId) {
      fetchPendingPayments();
    } else {
      toast.error('User ID not found. Please log in.');
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTimeRemaining) => {
        const updatedTimeRemaining: { [key: number]: number } = {};
        Object.keys(prevTimeRemaining).forEach((key) => {
          const paymentId = Number(key);
          const time = prevTimeRemaining[paymentId];
          if (time > 0) {
            updatedTimeRemaining[paymentId] = time - 1; // Decrement each second
          } else {
            updatedTimeRemaining[paymentId] = 0;
          }
        });
        return updatedTimeRemaining;
      });
    }, 1000);

    return () => clearInterval(intervalId); // Clear interval on unmount
  }, []);

  const fetchPendingPayments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/user/${userId}/pending`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      setPayments(response.data);
      setLoading(false);

      // Fetch time remaining for each payment
      response.data.forEach((payment: Payment) => fetchTimeRemaining(payment.id));
    } catch (error) {
      console.error('Error fetching pending payments:', error);
      
      setLoading(false);
    }
  };

  const fetchTimeRemaining = async (paymentId: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/time-remaining/${paymentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      setTimeRemaining((prevState) => ({
        ...prevState,
        [paymentId]: response.data.message, // Store the time in seconds
      }));
    } catch (error) {
      console.error(`Error fetching time remaining for payment ID ${paymentId}:`, error);
    }
  };

  // Helper function to format seconds into days, hours, minutes, and seconds
  const formatTimeRemaining = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  // Handle payment process for a specific payment
  const handlePay = (paymentId: number) => {
    navigate(`/complete-payment/${paymentId}`);
  };

  return (
    <>
      <Header />
      <div className="manage-payments-container">
        <div className="content-wrapper">
          <h1 className="page-title">Pending Payments</h1>
          {loading ? (
            <div className="loading-spinner">
              <FaSpinner className="spinner-icon" />
              <p>Loading payments...</p>
            </div>
          ) : payments.length > 0 ? (
            <div className="table-responsive">
              <table className="payments-table">
                <thead>
                  <tr>
                    <th>Payment Reference</th>
                    <th>Total Price</th>
                    <th>Order Placed On</th>
                    <th>Status</th>
                    <th>Time Remaining</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment) => (
                    <tr key={payment.id}>
                      <td>{payment.paymentReference}</td>
                      <td>R {payment.totalPrice.toFixed(2)}</td>
                      <td>{new Date(payment.createdAt).toLocaleString()}</td>
                      <td>
                        <span
                          className={`status-badge ${
                            payment.status === 'Pending' ? 'pending' : 'completed'
                          }`}
                        >
                          {payment.status}
                        </span>
                      </td>
                      <td className="time-remaining">
                        {timeRemaining[payment.id] !== undefined ? (
                          formatTimeRemaining(timeRemaining[payment.id])
                        ) : (
                          'Loading...'
                        )}
                      </td>
                      <td>
                        <button
                          className="pay-button"
                          onClick={() => handlePay(payment.id)}
                          disabled={timeRemaining[payment.id] <= 0}
                        >
                          Pay Now
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="no-payments">
              <FaExclamationCircle className="no-payments-icon" />
              <p>No pending payments found.</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default ManagePayments;

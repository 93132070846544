import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/ForgotPassword/ForgotPassword.css';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email) {
      toast.error('Please enter your email address.');
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/Auth/forgot-password`, { email });
      setEmailSent(true);
      toast.success('Password reset email has been sent.');
    } catch (error) {
      console.error('Error sending reset email:', error);
      toast.error('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-content">
        <h1>Forgot Password</h1>
        <p>Enter your email address below and we'll send you a link to reset your password.</p>

        <form onSubmit={handleForgotPassword} className="forgot-password-form">
          <div className="input-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              required
              className="email-input"
            />
          </div>

          <button type="submit" disabled={loading} className="submit-button">
            {loading ? 'Sending...' : 'Send Reset Link'}
          </button>
        </form>

        {emailSent && (
          <div className="email-sent-confirmation">
            <p>If an account with that email exists, a password reset link has been sent to your email address.</p>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
// src/components/Business/ManageSales/ManageSales.tsx
import React, { useState, useEffect } from 'react'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../style/Business/ManageSales/ManageSales.css';
import { Edit2, Trash2, Save, X } from 'lucide-react';
import Header from '../../Header and Footer/Header';
import Footer from '../../Header and Footer/Footer';
import { jwtDecode } from "jwt-decode";

interface Category {
  id: number;
  name: string;
  author: string;
  price: number;
  description: string;
  quantity: number;
  imageUrl: string;
  genre?: string; // Optional genre field
  ISBN: string;
}

type EditedCategory = Partial<Omit<Category, 'imageUrl'>> & { imageUrl?: string | File };

const ManageSales: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [editingCategoryId, setEditingCategoryId] = useState<number | null>(null);
  const [editedCategories, setEditedCategories] = useState<{ [key: number]: EditedCategory }>({});
  const [previewImages, setPreviewImages] = useState<{ [key: number]: string | null }>({});

  // Function to get userId from the JWT token
  const getUserIdFromToken = (): string | null => {
    const token = localStorage.getItem('jwtToken');
    if (!token) return null;

    try {
      const decodedToken = jwtDecode<{ userId: string }>(token);
      return decodedToken.userId;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return null;
    }
  };

  // Fetch categories by userId from backend
  const fetchCategories = async () => {
    const userId = getUserIdFromToken();
    if (!userId) {
      setError('User ID not found.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Category/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error('Failed to fetch categories.');
      }

      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to load categories.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Handle edit form input changes
  const handleEditChange = (
    categoryId: number,
    field: keyof EditedCategory,
    value: string | number | File
  ) => {
    setEditedCategories({
      ...editedCategories,
      [categoryId]: {
        ...editedCategories[categoryId],
        [field]: value,
      },
    });

    // If the field is imageUrl and it's a File, create a preview URL
    if (field === 'imageUrl' && value instanceof File) {
      const imageUrl = URL.createObjectURL(value);
      setPreviewImages({
        ...previewImages,
        [categoryId]: imageUrl,
      });
    }
  };

  // Handle category edit (send the update request to the backend)
  const handleEditCategory = async (categoryId: number) => {
    const editedCategory = editedCategories[categoryId] || {};
    const categoryToUpdate = categories.find((cat) => cat.id === categoryId);
    if (!categoryToUpdate) {
      toast.error('Category not found.');
      return;
    }

    const userId = getUserIdFromToken();
    if (!userId) {
      toast.error('User ID not found.');
      return;
    }

    const formData = new FormData();
    formData.append('ID', categoryId.toString());
    formData.append('Name', editedCategory.name ?? categoryToUpdate.name);
    formData.append('Author', editedCategory.author ?? categoryToUpdate.author);
    formData.append('Price', (editedCategory.price ?? categoryToUpdate.price).toString());
    formData.append('ISBN', editedCategory.ISBN ?? categoryToUpdate.ISBN);
    formData.append('Description', editedCategory.description ?? categoryToUpdate.description);
    formData.append('ImageURL', categoryToUpdate.imageUrl);
    formData.append('Genre', editedCategory.genre ?? categoryToUpdate.genre ?? '');
    formData.append('Quantity', (editedCategory.quantity ?? categoryToUpdate.quantity).toString());
    formData.append('UserID', userId.toString());

    // Check if the imageUrl is a File before appending it to formData
    if (editedCategory.imageUrl instanceof File) {
      formData.append('Image', editedCategory.imageUrl); // Appending image file if exists
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Category/update`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update category.');
      }

      toast.success('Item updated successfully!');
      setEditingCategoryId(null); // Exit edit mode

      // Clear edited data
      setEditedCategories((prev) => {
        const { [categoryId]: _, ...rest } = prev;
        return rest;
      });
      setPreviewImages((prev) => {
        const { [categoryId]: _, ...rest } = prev;
        return rest;
      });

      // Refresh categories after update
      await fetchCategories();
    } catch (error) {
      console.error('Error updating category:', error);
      toast.error('Failed to update category.');
    }
  };

  // Handle category removal
  const handleRemoveCategory = async (categoryId: number) => {
    if (window.confirm('Are you sure you want to remove this item from sale?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Category/delete/${categoryId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete category.');
        }

        toast.success('Item removed successfully!');
        // Remove the category from the state
        setCategories(categories.filter((category) => category.id !== categoryId));
      } catch (error) {
        console.error('Error deleting category:', error);
        toast.error('Failed to remove item.');
      }
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="manage-sales-page">
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Loading items...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header />
        <div className="manage-sales-page">
          <p className="error-message">{error}</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="manage-sales-page">
        <ToastContainer />
        <h1 className="manage-sales-title">Manage My Sales</h1>
        <div className="categories-list">
          {categories.length > 0 ? (
            categories.map((category) => {
              const isEditing = editingCategoryId === category.id;
              const editedCategory = editedCategories[category.id] || {};
              const previewImage = previewImages[category.id] || null;

              return (
                <div key={category.id} className="category-card">
                  {isEditing ? (
                    <div className="edit-category-form">
                      <h3 className="edit-category-title">Editing {category.name}</h3>
                      <div className="form-group">
                        <label htmlFor={`name-${category.id}`}>Name:</label>
                        <input
                          type="text"
                          id={`name-${category.id}`}
                          value={
                            editedCategory.name !== undefined
                              ? editedCategory.name
                              : category.name
                          }
                          onChange={(e) =>
                            handleEditChange(category.id, 'name', e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor={`author-${category.id}`}>Author:</label>
                        <input
                          type="text"
                          id={`author-${category.id}`}
                          value={
                            editedCategory.author !== undefined
                              ? editedCategory.author
                              : category.author
                          }
                          onChange={(e) =>
                            handleEditChange(category.id, 'author', e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor={`price-${category.id}`}>Price:</label>
                        <input
                          type="number"
                          id={`price-${category.id}`}
                          value={
                            editedCategory.price !== undefined
                              ? editedCategory.price
                              : category.price
                          }
                          onChange={(e) =>
                            handleEditChange(
                              category.id,
                              'price',
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor={`quantity-${category.id}`}>Quantity:</label>
                        <input
                          type="number"
                          id={`quantity-${category.id}`}
                          value={
                            editedCategory.quantity !== undefined
                              ? editedCategory.quantity
                              : category.quantity
                          }
                          onChange={(e) =>
                            handleEditChange(
                              category.id,
                              'quantity',
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor={`isbn-${category.id}`}>ISBN:</label>
                        <input
                          type="text"
                          id={`isbn-${category.id}`}
                          value={
                            editedCategory.ISBN !== undefined
                              ? editedCategory.ISBN
                              : category.ISBN
                          }
                          onChange={(e) =>
                            handleEditChange(category.id, 'ISBN', e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor={`description-${category.id}`}>Description:</label>
                        <textarea
                          id={`description-${category.id}`}
                          value={
                            editedCategory.description !== undefined
                              ? editedCategory.description
                              : category.description
                          }
                          onChange={(e) =>
                            handleEditChange(category.id, 'description', e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor={`genre-${category.id}`}>Genre:</label>
                        <input
                          type="text"
                          id={`genre-${category.id}`}
                          value={
                            editedCategory.genre !== undefined
                              ? editedCategory.genre
                              : category.genre ?? ''
                          }
                          onChange={(e) =>
                            handleEditChange(category.id, 'genre', e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor={`imageUrl-${category.id}`}>Item Image:</label>
                        <input
                          type="file"
                          id={`imageUrl-${category.id}`}
                          accept="image/*"
                          onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                              handleEditChange(category.id, 'imageUrl', e.target.files[0]);
                            }
                          }}
                        />
                        {(previewImage || category.imageUrl) && (
                          <img
                            src={previewImage || category.imageUrl}
                            alt="Item Preview"
                            className="category-image-preview"
                          />
                        )}
                      </div>
                      <div className="form-actions">
                        <button
                          className="save-button"
                          onClick={() => handleEditCategory(category.id)}
                        >
                          <Save size={16} /> Save Changes
                        </button>
                        <button
                          className="cancel-button"
                          onClick={() => {
                            setEditingCategoryId(null);
                            setEditedCategories((prev) => {
                              const { [category.id]: _, ...rest } = prev;
                              return rest;
                            });
                            setPreviewImages((prev) => {
                              const { [category.id]: _, ...rest } = prev;
                              return rest;
                            });
                          }}
                        >
                          <X size={16} /> Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="category-details">
                      {category.imageUrl && (
                        <img
                          src={
                            category.imageUrl.startsWith('/')
                              ? `${process.env.REACT_APP_API_BASE_URL}${category.imageUrl}`
                              : category.imageUrl
                          }
                          alt={category.name}
                          className="category-image"
                          onError={(e) => {
                            e.currentTarget.src =
                              'https://via.placeholder.com/400x200?text=Image+Not+Available';
                            e.currentTarget.alt = 'Placeholder image';
                          }}
                        />
                      )}
                      <div className="category-info">
                        <h2 className="category-name">{category.name}</h2>
                        <p className="category-description">{category.description}</p>
                        {category.genre && (
                          <p className="category-genre">
                            <strong>Genre:</strong> {category.genre}
                          </p>
                        )}
                        <p className="category-price">
                          <strong>Price:</strong> R{category.price.toFixed(2)}
                        </p>
                        <p className="category-quantity">
                          <strong>Quantity:</strong> {category.quantity}
                        </p>
                      </div>
                      <div className="category-actions">
                        <button
                          className="edit-button"
                          onClick={() => {
                            setEditingCategoryId(category.id);
                          }}
                        >
                          <Edit2 size={16} /> Edit
                        </button>
                        <button
                          className="remove-button"
                          onClick={() => handleRemoveCategory(category.id)}
                        >
                          <Trash2 size={16} /> Remove
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <p className="no-categories-message">No items on sale currently.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ManageSales;

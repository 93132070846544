// Categories.tsx

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import { jwtDecode } from "jwt-decode";
import '../../style/Categories/Categories.css';

interface Category {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  genre: string;
  price: number;
}

const Categories: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [genres, setGenres] = useState<string[]>([]);
  const [selectedGenre, setSelectedGenre] = useState<string>('All');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [visibleBooks, setVisibleBooks] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/Category`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        const data: Category[] = await response.json();
        setCategories(data);

        const genresSet: Set<string> = new Set<string>(
          data.map((category: Category) => category.genre)
        );
        const genresArray: string[] = Array.from(genresSet);
        setGenres(genresArray);

        const initialVisibleBooks = genresArray.reduce(
          (acc: { [key: string]: number }, genre: string) => {
            acc[genre] = 6;
            return acc;
          },
          {}
        );
        setVisibleBooks(initialVisibleBooks);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const getUserDataFromToken = (): { userId: string | null; securityCode: string | null } => {
    const token = localStorage.getItem('jwtToken');
    if (!token) return { userId: null, securityCode: null };

    try {
      const decodedToken: any = jwtDecode(token);
      return {
        userId: decodedToken.userId,
        securityCode: decodedToken.securityCode,
      };
    } catch (error) {
      console.error('Failed to decode token:', error);
      return { userId: null, securityCode: null };
    }
  };

  const addToCart = async (productId: number) => {
    const { userId, securityCode } = getUserDataFromToken(); // Retrieve userId and securityCode from the JWT token
    if (!userId || !securityCode) {
      toast.error('You need to log in to add items to the cart.');
      return;
    }
  
    const quantity = 1; // Default quantity to 1 for adding to cart
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Cart/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Ensure JSON content type
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`, // Attach JWT token in Authorization header
        },
        body: JSON.stringify({ userId: Number(userId), productId, quantity, securityCode }), // Send request body in JSON
      });
  
      if (!response.ok) {
        throw new Error(`Failed to add to cart. Status: ${response.status}`);
      }
  
      toast.success('Added to cart successfully!'); // Success message
    } catch (err) {
      console.error('Error adding to cart:', err);
      toast.error('Failed to add to cart.'); // Error message
    }
  };
  

  const addToWishlist = async (productId: number) => {
    const { userId, securityCode } = getUserDataFromToken(); // Retrieve userId and securityCode from the JWT token
    if (!userId || !securityCode) {
      toast.error('You need to log in to add items to the wishlist.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Wishlist/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Ensure JSON content type
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`, // Attach JWT token in Authorization header
        },
        body: JSON.stringify({ userId: Number(userId), productId, securityCode }), // Send request body in JSON
      });
  
      if (!response.ok) {
        throw new Error(`Failed to add to wishlist. Status: ${response.status}`);
      }
  
      toast.success('Added to wishlist successfully!'); // Success message
    } catch (err) {
      console.error('Error adding to wishlist:', err);
      toast.error('Failed to add to wishlist.'); // Error message
    }
  };
  

  const showMoreBooks = (genre: string) => {
    setVisibleBooks((prev) => ({
      ...prev,
      [genre]: prev[genre] + 6,
    }));
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading Categories...</p>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  // Apply filtering based on the selected genre
  const filteredCategories =
    selectedGenre === 'All'
      ? categories
      : categories.filter((category) => category.genre === selectedGenre);

  const genreGroups = filteredCategories.reduce(
    (groups: { [key: string]: Category[] }, category) => {
      if (!groups[category.genre]) {
        groups[category.genre] = [];
      }
      groups[category.genre].push(category);
      return groups;
    },
    {}
  );

  return (
    <div className="categories-page-container">
      <Header />
      <ToastContainer />
      <div className="categories-page">
        <div className="filter-container">
          <label htmlFor="genre-select">Filter by Genre:</label>
          <select
            id="genre-select"
            value={selectedGenre}
            onChange={(e) => setSelectedGenre(e.target.value)}
          >
            <option value="All">All</option>
            {genres.map((genre) => (
              <option key={genre} value={genre}>
                {genre}
              </option>
            ))}
          </select>
        </div>
        {Object.keys(genreGroups).map((genre) => (
          <div key={genre} className="genre-group">
            <h2 className="genre-title">{genre}</h2>
            <div className="categories-grid">
              {genreGroups[genre].slice(0, visibleBooks[genre] || 6).map((category) => (
                <div key={category.id} className="category-card">
                  <div className="category-image-container">
  <Link to={`/categories/${category.id}`}>
    <img
      src={
        category.imageUrl.startsWith('/')
          ? `${process.env.REACT_APP_API_BASE_URL}${category.imageUrl}`
          : category.imageUrl
      }
      alt={category.name}
      className="category-image"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Ensures the image covers the container without distortion
        borderRadius: '5px', // Optional: for rounded corners
      }}
      onError={(e) => {
        e.currentTarget.src =
          'https://via.placeholder.com/400x200?text=Image+Not+Available';
        e.currentTarget.alt = 'Placeholder image';
      }}
    />
  </Link>
</div>

                  <div className="category-info">
                    <h3 className="category-name">{category.name}</h3>
                    <p className="category-description">{category.description}</p>
                  </div>
                  <div className="category-footer">
                    <div className="category-price">R {category.price.toFixed(2)}</div>
                    <div className="category-actions">
                      <button
                        className="icon-button cart-button"
                        onClick={() => addToCart(category.id)}
                        aria-label="Add to cart"
                      >
                        <i className="fas fa-shopping-cart"></i>
                      </button>
                      <button
                        className="icon-button wishlist-button"
                        onClick={() => addToWishlist(category.id)}
                        aria-label="Add to wishlist"
                      >
                        <i className="fas fa-heart"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {genreGroups[genre].length > (visibleBooks[genre] || 6) && (
              <button onClick={() => showMoreBooks(genre)} className="show-more-button">
                Show More
              </button>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Categories;

// src/components/GetOriginalPrice.tsx
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../Header and Footer/Header';
import Footer from '../../Header and Footer/Footer';
import '../../../style/Admin/Sell/GetOriginalPrice.css';

const GetOriginalPrice: React.FC = () => {
  const [markedUpPrice, setMarkedUpPrice] = useState<string>('');
  const [originalPrice, setOriginalPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarkedUpPrice(event.target.value);
  };

  const calculateOriginalPrice = async () => {
    if (!markedUpPrice || isNaN(Number(markedUpPrice)) || Number(markedUpPrice) <= 0) {
      toast.error('Please enter a valid marked-up price.');
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Category/calculate-base-price`, {
        params: { markedUpPrice: Number(markedUpPrice) },
      });
  
      // Adjusted to check for lowercase "basePrice" as returned in the API response
      if (response.data && response.data.basePrice !== undefined) {
        setOriginalPrice(response.data.basePrice);
        toast.success('Original price calculated successfully!');
      } else {
        toast.error('Original price could not be calculated. Please try again.');
      }
    } catch (error) {
      toast.error('Failed to calculate original price.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      <Header />
      <div className="get-original-price-container">
        <h1>Get Original Price</h1>
        <div className="form-group">
          <label htmlFor="markedUpPrice">Enter Marked-Up Price (ZAR):</label>
          <input
            type="number"
            id="markedUpPrice"
            value={markedUpPrice}
            onChange={handleInputChange}
            placeholder="Enter the marked-up price"
          />
        </div>
        <button onClick={calculateOriginalPrice} disabled={loading}>
          {loading ? 'Calculating...' : 'Get Original Price'}
        </button>
        {originalPrice !== null && (
          <div className="result">
            <p>Original Price: ZAR {originalPrice.toFixed(2)}</p>
          </div>
        )}
        <ToastContainer position="top-right" autoClose={5000} />
      </div>
      <Footer />
    </>
  );
};

export default GetOriginalPrice;

import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/Sell/BatchSell.css';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';

const BatchSell: React.FC = () => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [failedCategories, setFailedCategories] = useState<any[]>([]);

  const handleCsvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCsvFile(e.target.files[0]);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setImageFiles(filesArray);
    }
  };

  const validateFiles = (): boolean => {
    if (!csvFile) {
      toast.error('Please upload a CSV file.');
      return false;
    }
    if (imageFiles.length === 0) {
      toast.error('Please upload at least one image.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFiles()) {
      return;
    }
  
    setLoading(true); // Start loading animation
  
    const formData = new FormData();
    formData.append('csvFile', csvFile as File);
  
    imageFiles.forEach((file) => {
      formData.append('images', file);
    });
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Category/add-batch-categories-from-csv`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
  
      if (response.status === 200) {
        const { successfulCategories, failedCategories } = response.data;
  
        toast.success(`Batch upload successful! ${successfulCategories.length} categories added.`);
  
        if (failedCategories.length > 0) {
          setFailedCategories(failedCategories);
          toast.error(`${failedCategories.length} categories failed to upload.`);
        } else {
          // Clear inputs if all categories were successful
          setCsvFile(null);
          setImageFiles([]);
          (document.getElementById('csvFile') as HTMLInputElement).value = '';
          (document.getElementById('imageFiles') as HTMLInputElement).value = '';
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data;
  
        if (error.response?.status === 500 && errorData?.failedCategories) {
          setFailedCategories(errorData.failedCategories);
          toast.error('Some categories failed to upload due to a server error.');
        } else {
          toast.error(errorData?.message || 'Batch upload failed.');
        }
      } else {
        toast.error('An unexpected error occurred.');
      }
      console.error('Error during batch upload:', error);
    } finally {
      setLoading(false); // Stop loading animation
    }
  };
  

  return (
    <>
      <Header />
      <div className="sell-page-container">
        <div className="sell-form-wrapper">
          <h1 className="page-title">Batch Sell Books</h1>
          <div className="form-group">
            <label htmlFor="csvFile">Upload CSV File *</label>
            <input
              type="file"
              id="csvFile"
              accept=".csv"
              onChange={handleCsvChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="imageFiles">Upload Images *</label>
            <input
              type="file"
              id="imageFiles"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </div>
          <button onClick={handleSubmit} className="sell-button" disabled={loading}>
            {loading ? 'Uploading...' : 'Submit Batch'}
          </button>
          {loading && <div className="loading-spinner"></div>}
          <ToastContainer position="top-right" autoClose={5000} />

          {/* Modal or Section to Display Failed Categories */}
          {failedCategories.length > 0 && (
            <div className="error-modal">
              <h2>Failed Categories</h2>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {failedCategories.map((failed, index) => (
                    <tr key={index}>
                      <td>{failed.name}</td>
                      <td>{failed.error}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => setFailedCategories([])} className="close-button">
                Close
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BatchSell;

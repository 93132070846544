// src/components/Checkout/CheckoutPart1.tsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { MapPin, Truck, Package, CreditCard } from 'lucide-react';
import { useNavigate, Link  } from 'react-router-dom';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Checkout/CheckoutPart1.css';
import { jwtDecode } from "jwt-decode";

interface Address {
  id: number;
  recipientName: string;
  recipientMobileNumber: string;
  street: string;
  complexBuilding?: string;
  businessName?: string;
  suburb: string;
  city: string;
  province: string;
  postalCode: string;
  isBusinessAddress: boolean;
}

interface ShippingMethod {
  id: number;
  shippingMethodName: string;
  description: string;
  price: number;
  estimatedDeliveryTime: string;
}

interface OrderItem {
  productId: number;
  quantity: number;
  price: number;
  productName?: string;
  imageUrl?: string;
}

interface InterimOrderSummary {
  id: number;
  userId: number;
  sellerId: number;
  totalPrice: number;
  createdAt: string;
  items: OrderItem[];
}

interface DecodedToken {
  userId: string;
  [key: string]: any;
}

// Function to get userId from the JWT token
const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const CheckoutPart1: React.FC = () => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [savedShippingMethods, setSavedShippingMethods] = useState<ShippingMethod[]>([]);
  const [interimOrder, setInterimOrder] = useState<InterimOrderSummary | null>(null);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<ShippingMethod | null>(null);

  const navigate = useNavigate();
  const userId = getUserIdFromToken(); // Get userId from the token
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);

  // Fetch addresses
  const fetchAddresses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Address/user-addresses/${userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      const data = await response.json();
      if (!response.ok) throw new Error('Failed to fetch addresses');
      setAddresses(data);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      setError('Failed to load addresses');
    } finally {
      setLoading(false);
    }
  };

  const categoryUserIdOne = localStorage.getItem('CategoryUserIdOne');

  // Fetch saved shipping methods
  const fetchSavedShippingMethods = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Shipping/SavedShippingMethods/${categoryUserIdOne}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      const data = await response.json();
      if (!response.ok) throw new Error('Failed to fetch saved shipping methods');
      setSavedShippingMethods(data);
    } catch (error) {
      console.error('Error fetching saved shipping methods:', error);
      toast.error('Failed to retrieve saved shipping methods.');
    }
  };

  // Fetch category by productId for each item
  const fetchCategoryById = async (productId: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Category/${productId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch category');
      const categoryData = await response.json();
      return categoryData;
    } catch (err) {
      console.error('Error fetching category:', err);
      return null;
    }
  };

  const enrichOrderItems = async (items: OrderItem[]) => {
    const enrichedItems = await Promise.all(
      items.map(async item => {
        const categoryData = await fetchCategoryById(item.productId);
        return categoryData
          ? { ...item, productName: categoryData.name, imageUrl: categoryData.imageUrl }
          : item;
      })
    );
    return enrichedItems;
  };

  const processPaymentCheckout = async () => {
    const interimCheckoutPart2Id = parseInt(localStorage.getItem('interimCheckoutPart2Id') || '0');
    const shippingMethodId = parseInt(localStorage.getItem('selectedShippingMethodId') || '0'); // Get ShippingMethodId from localStorage

    const checkoutRequest = {
      userId: parseInt(userId || '0'), // Use userId from the token
      interimCheckoutPart2Id,
      shippingMethodId, // Include shippingMethodId in request
      paymentMethod: "Card",
      notes: "Checkout payment",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/process`,
        checkoutRequest,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log("Full response data:", response.data);
      const paymentId = response.data.paymentId;

      if (paymentId) {
        toast.success("Payment processed successfully!");
        localStorage.setItem('paymentId', paymentId.toString());
        navigate("/payment");
      } else {
        console.error("PaymentId is undefined in the response.", response.data);
        toast.error("There was an error processing your payment.");
      }
    } catch (error) {
      console.error('Error during checkout or payment processing:', error);
      toast.error("There was an error processing your payment.");
    }
  };

  const fetchInterimOrderSummary = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Order/interim-order-summary/${userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      const data: InterimOrderSummary = await response.json();
      if (!response.ok) throw new Error('Failed to fetch interim order summary');

      const enrichedItems = await enrichOrderItems(data.items);
      setInterimOrder({ ...data, items: enrichedItems });

      const itemIds = data.items.map(item => item.productId);
      localStorage.setItem('cartItemIds', JSON.stringify(itemIds));
    } catch (error) {
      console.error('Error fetching interim order summary:', error);
      setError('Failed to load order summary');
    } finally {
      setLoading(false);
    }
  };

  const handleShippingMethodChange = (method: ShippingMethod) => {
    setSelectedShippingMethod(method);
    localStorage.setItem('selectedShippingMethodId', method.id.toString());
  };

  const handleAddressSelection = (addressId: number) => {
    setSelectedAddressId(addressId);
    localStorage.setItem('selectedAddressId', addressId.toString());
  };

  const handleContinueToPayment = async () => {
    const addressId = localStorage.getItem('selectedAddressId');
    const shippingMethodId = localStorage.getItem('selectedShippingMethodId');
    const itemIds = JSON.parse(localStorage.getItem('cartItemIds') || '[]'); // Retrieves the repeated `itemIds`
    const userId = parseInt(getUserIdFromToken() || '0'); // Ensure userId is available
    const interimOrderSummaryId = parseInt(localStorage.getItem("InterimOrderID_Summary") || '0'); // Retrieve InterimOrderID_Summary

    if (!addressId) {
      toast.error("Please select a delivery address.");
      return;
    }

    if (!shippingMethodId || itemIds.length === 0 || !interimOrderSummaryId) {
      toast.error("Please select a shipping method.");
      return;
    }

    const checkoutRequest = {
      addressId: parseInt(addressId),
      shippingMethodId: parseInt(shippingMethodId),
      itemIds: JSON.stringify(itemIds), // Ensure `itemIds` includes repeated product IDs
      userId,
      interimOrderSummaryId // Include the retrieved interimOrderSummaryId
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/Order/interim-checkout-part2`, checkoutRequest, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        const interimCheckoutPart2Id = response.data.id;
        localStorage.setItem('interimCheckoutPart2Id', interimCheckoutPart2Id);
        toast.success("Interim checkout part 2 saved successfully!");

        // Step 1: Process the payment
        await processPaymentCheckout();
      }
    } catch (error) {
      console.error('Error saving interim checkout part 2:', error);
      toast.error("There was an error saving your checkout data.");
    }
  };

  const subtotal = interimOrder?.items.reduce((acc, item) => acc + item.quantity, 0) || 0;
  const totalPrice = (interimOrder?.totalPrice || 0) + (selectedShippingMethod?.price || 0);

  useEffect(() => {
    fetchAddresses();
    fetchSavedShippingMethods();
    fetchInterimOrderSummary();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading checkout...</div>
      </div>
    );
  }

  return (
    <div className="checkout-page">
      <Header />
      <div className="checkout-container">
        <ToastContainer />
        {error && <div className="error-message">{error}</div>}

        <div className="progress-steps">
          <div className="steps">
            <div className="step active">
              <div className="step-content">
                <div className="step-box">
                  <MapPin className="icon" />
                  <span>Shipping</span>
                </div>
                <div className="step-arrow"></div>
              </div>
            </div>
            <div className="step">
              <div className="step-content">
                <div className="step-box">
                  <CreditCard className="icon" />
                  <span>Payment</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content-grid">
          <div className="left-column">
            <div className="section">
              <div className="section-header">
                <h2 className="section-title">
                  <MapPin className="icon section-icon" />
                  Delivery Address
                </h2>
                <button className="new-address-button">
      <Link to="/manage-address" >
        Manage Address
      </Link>
    </button>
              </div>
              {addresses.length === 0 ? (
                <p className="no-address-message">No saved addresses available.</p>
              ) : (
                <div className="address-list">
                  {addresses.map(address => (
                    <label key={address.id} className="address-label">
                      <div
                        className={`address-card ${selectedAddressId === address.id ? 'selected' : ''}`}
                      >
                        <div className="address-card-content">
                          <input
                            type="radio"
                            name="selectedAddress"
                            value={address.id}
                            checked={selectedAddressId === address.id}
                            onChange={() => handleAddressSelection(address.id)}
                            className="address-radio"
                            required
                          />
                          <div className="address-details">
                            <div className="address-header">
                              <span className="recipient-name">{address.recipientName}</span>
                              <span className="address-type">
                                {address.isBusinessAddress ? 'Business' : 'Residential'}
                              </span>
                              {/* You can remove the Default label if not needed */}
                            </div>
                            <p className="address-line">{address.street}</p>
                            {address.complexBuilding && <p className="address-line">{address.complexBuilding}</p>}
                            <p className="address-line">{`${address.suburb}, ${address.city}`}</p>
                            <p className="address-line">{`${address.province}, ${address.postalCode}`}</p>
                            <p className="mobile-number">📱 {address.recipientMobileNumber}</p>
                          </div>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              )}
              {!selectedAddressId && (
                <p className="address-warning">
                  Please select a delivery address before continuing.
                </p>
              )}
            </div>

            <div className="section">
              <h2 className="section-title">
                <Truck className="icon section-icon" />
                Saved Shipping Methods
              </h2>
              <div className="shipping-methods">
                {savedShippingMethods.map(method => (
                  <label key={method.id} className="shipping-method-label">
                    <div
                      className={`shipping-method-card ${selectedShippingMethod?.id === method.id ? 'selected' : ''}`}
                    >
                      <div className="shipping-method-content">
                        <input
                          type="radio"
                          name="shipping"
                          value={method.id}
                          checked={selectedShippingMethod?.id === method.id}
                          onChange={() => handleShippingMethodChange(method)}
                          className="shipping-radio"
                          required
                        />
                        <div className="shipping-details">
                          <div className="shipping-header">
                            <span className="shipping-name">{method.shippingMethodName}</span>
                            <span className="shipping-price">R{method.price}</span>
                          </div>
                          <p className="shipping-description">{method.description}</p>
                          <p className="estimated-delivery">Estimated delivery: {method.estimatedDeliveryTime}</p>
                        </div>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
              {!selectedShippingMethod && (
                <p className="shipping-warning">
                  Please select a shipping method before continuing.
                </p>
              )}
            </div>
          </div>

          <div className="right-column">
            <div className="order-summary">
              <h2 className="summary-title">
                <Package className="icon summary-icon" />
                Order Summary
              </h2>
              <div className="summary-content">
                <div className="summary-row">
                  <span>Subtotal ({subtotal} items)</span>
                  <span>R{interimOrder?.totalPrice.toFixed(2)}</span>
                </div>
                <div className="summary-row">
                  <span>Shipping</span>
                  <span>R{selectedShippingMethod?.price.toFixed(2) || '0.00'}</span>
                </div>
                <div className="summary-total">
                  <span>Total</span>
                  <span>R{totalPrice.toFixed(2)}</span>
                </div>
                <button
                  className={`continue-button ${
                    selectedShippingMethod && selectedAddressId ? '' : 'disabled'
                  }`}
                  disabled={!selectedShippingMethod || !selectedAddressId}
                  onClick={handleContinueToPayment}
                >
                  Continue to Payment
                </button>
                {(!selectedAddressId || !selectedShippingMethod) && (
                  <p className="warning-message">
                    Please select a delivery address and shipping method before continuing.
                  </p>
                )}
              </div>

              <div className="cart-items">
                <h3 className="items-title">Items in Cart</h3>
                {interimOrder?.items.map(item => (
                  <div className="cart-item" key={item.productId}>
                    <img
                      src={
                        item.imageUrl?.startsWith('/')
                          ? `${process.env.REACT_APP_API_BASE_URL}${item.imageUrl}`
                          : item.imageUrl || 'https://via.placeholder.com/100x150?text=Image+Not+Available'
                      }
                      alt="Item"
                      className="item-image"
                    />
                    <div className="item-details">
                      <h4 className="item-name">{item.productName || 'Product Name'}</h4>
                      <p className="item-price">R{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutPart1;

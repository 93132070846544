// src/components/Header and Footer/Header.tsx

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faSearch,
  faShoppingCart,
  faSignOutAlt,
  faUser,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import "../../style/Header and Footer/Header.css";

interface Category {
  id: number;
  name: string;
  description: string;
  genre: string;
}

const Header: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Category[]>([]);
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("jwtToken");

  useEffect(() => {
    if (searchTerm) {
      const fetchSearchResults = async () => {
        try {
          let url = `${process.env.REACT_APP_API_BASE_URL}/api/Category/search?`;

          if (!isNaN(Number(searchTerm))) {
            url += `isbn=${searchTerm}`;
          } else if (searchTerm.toLowerCase().includes("author:")) {
            url += `author=${searchTerm.replace("author:", "").trim()}`;
          } else {
            url += `name=${searchTerm}`;
          }

          const response = await fetch(url);
          if (!response.ok) throw new Error("Network response was not ok");
          const data: Category[] = await response.json();
          setSearchResults(data);
        } catch (error) {
          console.error("Error fetching search results:", error);
          setSearchResults([]);
        }
      };

      const timer = setTimeout(fetchSearchResults, 300);
      return () => clearTimeout(timer);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userId");
    navigate("/login");
  };

  return (
    <header className="header">
      <div className="header-content">
        <Logo />
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchResults={searchResults}
        />
        <div className="nav-actions-container">
          <Navigation />
          <HeaderActions isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        </div>
      </div>
    </header>
  );
};

const Logo: React.FC = () => (
  <div className="logo">
    <Link to="/landingpage">The Second Hand Book Dealer</Link>
  </div>
);

const Navigation: React.FC = () => (
  <nav className="nav">
    <Link to="/categories" title="Categories">
      <FontAwesomeIcon icon={faShoppingBasket} />
    </Link>
    <Link to="/cart" title="Cart">
      <FontAwesomeIcon icon={faShoppingCart} />
    </Link>
    <Link to="/wishlist" title="Wishlist">
      <FontAwesomeIcon icon={faHeart} />
    </Link>
    <Link to="/profile" title="Profile">
      <FontAwesomeIcon icon={faUser} />
    </Link>
  </nav>
);

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  searchResults: Category[];
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  setSearchTerm,
  searchResults,
}) => (
  <div className="search">
    <input
      type="text"
      placeholder="Search books ..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    <button aria-label="Search">
      <FontAwesomeIcon icon={faSearch} />
    </button>
    {searchResults.length > 0 && (
      <div className="search-results">
        {searchResults.map((category) => (
          <Link
            to={`/categories/${category.id}`}
            key={category.id}
            className="search-result-item"
          >
            <h6>{category.name}</h6>
          </Link>
        ))}
      </div>
    )}
  </div>
);

interface HeaderActionsProps {
  isLoggedIn: boolean;
  handleLogout: () => void;
}

const HeaderActions: React.FC<HeaderActionsProps> = ({
  isLoggedIn,
  handleLogout,
}) => (
  <div className="header-actions">
    {isLoggedIn ? (
      <button onClick={handleLogout} className="logout-button">
        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
      </button>
    ) : (
      <Link to="/login" className="login-button">
        <FontAwesomeIcon icon={faUser} /> Login
      </Link>
    )}
  </div>
);

export default Header;

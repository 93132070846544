
import React, { useState } from 'react';
import '../../style/Register/Register.css';
import { useNavigate, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface FormData {
  fullName: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
}

// Input sanitization function
const sanitizeInput = (input: string): string => {
  // Remove leading and trailing whitespace
  let sanitizedInput = input.trim();
  // Remove any HTML tags
  sanitizedInput = sanitizedInput.replace(/<[^>]*>/g, '');
  return sanitizedInput;
};

const Register: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let newValue = value;
    if (name === 'username') {
      newValue = value.replace(/@+/g, '');
    }

    setFormData({
      ...formData,
      [name]: newValue,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateForm = () => {
    const newErrors: Partial<FormData> = {};

    if (!formData.fullName) newErrors.fullName = 'Full Name is required';
    if (!formData.username) {
      newErrors.username = 'Username is required';
    } else if (formData.username.length < 3) {
      newErrors.username = 'Username must be at least 3 characters long';
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!formData.password) newErrors.password = 'Password is required';
    else if (formData.password.length < 6)
      newErrors.password = 'Password must be at least 6 characters long';

    if (!formData.confirmPassword)
      newErrors.confirmPassword = 'Confirm Password is required';
    else if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = 'Passwords do not match';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        // Sanitize inputs before sending
        const dataToSend = {
          fullName: sanitizeInput(formData.fullName),
          username: sanitizeInput(formData.username),
          email: sanitizeInput(formData.email),
          password: sanitizeInput(formData.password),
          confirmPassword: sanitizeInput(formData.confirmPassword),
        };

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Auth/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSend),
        });

        if (response.ok) {
          toast.success('Registration successful! Redirecting to login page.');
          setTimeout(() => {
            navigate('/login');
          }, 3000); // Redirect after 3 seconds
        } else {
          const errorMessage = await response.text();
          toast.error(`Registration failed: ${errorMessage}`);
        }
      } catch (error) {
        console.error('Error during registration:', error);
        toast.error('An error occurred during registration. Please try again later.');
      }
    } else {
      toast.warn('Please correct the highlighted fields.');
    }
  };

  return (
    <div className="register-page">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
      <div className="register-form-container">
        <div className="register-heading">
          <h2>Sign Up</h2>
          <p>Create your account to get full access</p>
        </div>
        <form onSubmit={handleSubmit} className="register-form">
          {/* Full Name */}
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
              className={errors.fullName ? 'error-input' : ''}
            />
            {errors.fullName && <span className="error-message">{errors.fullName}</span>}
          </div>

          {/* Username */}
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <div className="username-input">
              <span>@</span>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Enter username"
                className={errors.username ? 'error-input' : ''}
              />
            </div>
            {errors.username && <span className="error-message">{errors.username}</span>}
          </div>

          {/* Email Address */}
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email address"
              className={errors.email ? 'error-input' : ''}
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>

          {/* Password */}
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter password"
              className={errors.password ? 'error-input' : ''}
            />
            {errors.password && <span className="error-message">{errors.password}</span>}
          </div>

          {/* Confirm Password */}
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm password"
              className={errors.confirmPassword ? 'error-input' : ''}
            />
            {errors.confirmPassword && (
              <span className="error-message">{errors.confirmPassword}</span>
            )}
          </div>

          {/* Form Footer */}
          <div className="register-footer">
            <p>
              Already have an account?{' '}
              <Link to="/login" className="login-link">
                Login
              </Link>{' '}
              here
            </p>
            <button type="submit" className="register-button">
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
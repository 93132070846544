  // src/components/Payment/CompletePayment.tsx
  import React, { useEffect, useState, ChangeEvent } from 'react';
  import axios from 'axios';
  import { useParams } from 'react-router-dom';
  import Header from '../Header and Footer/Header';
  import Footer from '../Header and Footer/Footer';
  import { ToastContainer, toast } from 'react-toastify';
  import { FaRegFileAlt, FaUpload, FaSpinner } from 'react-icons/fa';
  import 'react-toastify/dist/ReactToastify.css';
  import '../../style/Payment/CompletePayment.css';

  interface PaymentDetails {
    id: number;
    userId: number;
    interimCheckoutPart2Id: number;
    paymentReference: string;
    totalPrice: number;
    shippingPrice: number;
    createdAt: string;
    status: string;
    notes?: string;
    proofOfPayment?: string;
    proofOfPaymentType?: string;
    lastUpdatedBy?: number;
    lastUpdatedComments?: string;
    lastUpdatedAt?: string;
  }

  interface OrderItem {
    productId: number;
    quantity: number;
    price: number;
    name: string;
  }

  interface InterimOrderSummary {
    id: number;
    userId: number;
    productName: string;
    sellerId: number;
    totalPrice: number;
    createdAt: string;
    items: OrderItem[];
  }

  const CompletePayment: React.FC = () => {
    const { paymentId } = useParams<{ paymentId: string }>();
    const [payment, setPayment] = useState<PaymentDetails | null>(null);
    const [orderSummary, setOrderSummary] = useState<InterimOrderSummary | null>(null);
    const [proofOfPayment, setProofOfPayment] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);


    useEffect(() => {
      if (paymentId) {
        fetchPaymentDetails(paymentId);
        fetchOrderSummary(paymentId);
      }
    }, [paymentId]);

    const fetchPaymentDetails = async (id: string) => {
      setIsFetching(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Payment/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        });
        setPayment(response.data);
      } catch (error) {
        console.error('Error fetching payment details:', error);
        toast.error('Failed to fetch payment details.');
      } finally {
        setIsFetching(false);
      }
    };

    const fetchOrderSummary = async (paymentId: string) => {
      setIsFetching(true);
      try {
          // Fetch the interim order summary first
          const response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/api/Payment/interim-order-summary/${paymentId}`,
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                  },
              }
          );

          const summaryData = response.data;
          setOrderSummary(summaryData);

          // Extract product IDs as a simple comma-separated string without brackets
          const itemIds = summaryData.items.map((item: OrderItem) => item.productId).join(',');

          // Fetch product names using the new endpoint without brackets around itemIds
          const nameResponse = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/api/Category/item-names?itemIds=${itemIds}`,
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                  },
              }
          );

          const productNames = nameResponse.data;

          // Map product names to order items
          const itemsWithNames = summaryData.items.map((item: OrderItem, index: number) => ({
              ...item,
              name: productNames[index] || 'Unknown Product',
          }));

          setOrderSummary({ ...summaryData, items: itemsWithNames });
      } catch (error) {
          console.error('Error fetching order summary:', error);
          toast.error('Failed to fetch order summary.');
      } finally {
          setIsFetching(false);
      }
  };



    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        setProofOfPayment(event.target.files[0]);
      } else {
        setProofOfPayment(null);
      }
    };

    const handleProofUpload = async () => {
      if (!proofOfPayment) {
        toast.error('Please select a proof of payment file before uploading.');
        return;
      }

      setIsUploading(true);
      try {
        const formData = new FormData();
        formData.append('proofOfPayment', proofOfPayment);

        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/Payment/upload-proof/${paymentId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        toast.success('Proof of payment uploaded successfully.');
        fetchPaymentDetails(paymentId as string); // Refresh payment details after upload
        setProofOfPayment(null); // Reset file input
      } catch (error) {
        console.error('Error uploading proof of payment:', error);
        toast.error('Failed to upload proof of payment. Please try again.');
      } finally {
        setIsUploading(false);
      }
    };

    return (
      <>
        <Header />
        <div className="complete-payment-container">
          <div className="content-wrapper">
            <h1 className="page-title">Complete Your Payment</h1>
            {isFetching ? (
              <div className="loading-spinner">
                <FaSpinner className="spinner-icon" />
                <p>Loading payment details...</p>
              </div>
            ) : payment ? (
              <div className="payment-details-card">
                <div className="payment-summary">
                  <h2>Payment Summary</h2>
                  <div className="detail-row">
                    <span className="detail-label">Payment Reference:</span>
                    <span className="detail-value">{payment.paymentReference}</span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Total Price:</span>
                    <span className="detail-value">R {payment.totalPrice.toFixed(2)}</span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Status:</span>
                    <span className="detail-value status">{payment.status}</span>
                  </div>

                  {/* Display Interim Order Summary */}
                  {orderSummary && (
      <div className="order-summary">
          <h2>Order Summary</h2>
          <div className="detail-row">
              <span className="detail-label">Seller ID:</span>
              <span className="detail-value">{orderSummary.sellerId}</span>
          </div>

          {orderSummary.items.map((item, index) => (
              <div key={index} className="order-item">
                  <div className="item-detail-row">
                      <p className="detail"><strong>Product Name/s:</strong> {item.name}</p>
                      <p className="detail"><strong>Quantity:</strong> {item.quantity}</p>
                      <p className="detail"><strong>Price:</strong> R {item.price.toFixed(2)}</p>
                  </div>
              </div>
          ))}
      </div>
  )}


                  {/* Bank Details Section */}
                  <div className="bank-details">
                    <h2>Bank Details</h2>
                    <div className="bank-details-content">
                    <p>
      <strong>Account Name:</strong> The Second Hand Book Dealer (Pty) Ltd
    </p>
    <p>
      <strong>Bank:</strong> Nedbank Limited
    </p>
    <p>
      <strong>Account Type:</strong> Current Account
    </p>
    <p>
      <strong>Account Number:</strong> 1275278124
    </p>
    <p>
      <strong>Branch Code:</strong> 198765
    </p>
                    </div>
                  </div>
                </div>

                {/* Upload Proof of Payment Section */}
                <div className="upload-proof-section">
                  <h2>Upload Proof of Payment</h2>
                  <p>Please upload your proof of payment to complete the process.</p>
                  <div className="file-input-wrapper">
                    <input
                      type="file"
                      id="proofOfPayment"
                      className="file-input"
                      onChange={handleFileChange}
                      accept="image/*,.pdf"
                    />
                    <label htmlFor="proofOfPayment" className="file-input-label">
                      <FaRegFileAlt className="file-icon" />
                      {proofOfPayment ? proofOfPayment.name : 'Choose a file'}
                    </label>
                  </div>
                  <button
                    onClick={handleProofUpload}
                    className="upload-button"
                    disabled={isUploading || !proofOfPayment}
                  >
                    {isUploading ? (
                      <>
                        <FaSpinner className="spinner-icon" />
                        Uploading...
                      </>
                    ) : (
                      <>
                        <FaUpload className="upload-icon" />
                        Upload Proof of Payment
                      </>
                    )}
                  </button>
                  {payment.proofOfPayment && (
                    <div className="existing-proof">
                      <p>Your existing proof of payment:</p>
                      <a
                        href={payment.proofOfPayment}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="proof-link"
                      >
                        View Proof
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p className="error-message">Payment details not found.</p>
            )}
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </>
    );
  };

  export default CompletePayment;

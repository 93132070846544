// src/components/Cart/Cart.tsx

import React, { useState, useEffect } from 'react';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/Cart/Cart.css';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";

interface CartItem {
  id: number;
  productName: string;
  quantity: number;
  price: number;
  categoryName: string;
  imageUrl: string;
  productId: number;
  availableQuantity: number; // Added to store available stock
}

interface GroupedCartItem {
  categoryUserId: number;
  items: CartItem[];
}

interface DecodedToken {
  userId: string;
  securityCode: string;
  [key: string]: any;
}

const getUserDataFromToken = (): { userId: string | null; securityCode: string | null } => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return { userId: null, securityCode: null };

  try {
    const decodedToken: any = jwtDecode(token);
    return {
      userId: decodedToken.userId || null,
      securityCode: decodedToken.securityCode || null,
    };
  } catch (error) {
    console.error('Failed to decode token:', error);
    return { userId: null, securityCode: null };
  }
};

const Cart: React.FC = () => {
  const [groupedCartItems, setGroupedCartItems] = useState<GroupedCartItem[]>([]);
  const [sellerNames, setSellerNames] = useState<{ [key: number]: string }>({});
  const [total, setTotal] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const fetchSellerNames = async (sellerIds: number[]) => {
    const nameMap: { [key: number]: string } = {};
    await Promise.all(
      sellerIds.map(async (id) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/Auth/user/fullname/${id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
              },
            }
          );
          nameMap[id] = response.data.fullName;
        } catch (error) {
          console.error(`Error fetching name for seller ID ${id}:`, error);
          nameMap[id] = `Unknown Seller (ID: ${id})`;
        }
      })
    );
    setSellerNames(nameMap);
  };

  const fetchAllQuantities = async (productIds: number[]): Promise<{ [key: number]: number }> => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Category/get-quantities`,
        JSON.stringify(productIds),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching all quantities:', error);
      throw error;
    }
  };

  const fetchCartItemsGrouped = async () => {
    const { userId, securityCode } = getUserDataFromToken();
    if (!userId || !securityCode) {
      setError('User is not logged in.');
      setLoading(false);
      return;
    }

    try {
      // According to the backend code: GetCartItems uses GET with query parameters
      // Endpoint: GET /Cart/grouped-items?userId=...
      // This endpoint does not require security code in the posted backend code. If it does, add it as query param.
      // The posted backend code for /Cart/grouped-items does NOT show security code usage.
      // If security code is required everywhere, we must add it as well.
      // However, the posted code does not show security code for grouped-items GET.
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Cart/grouped-items?userId=${encodeURIComponent(userId)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );

      const groupedItems = response.data as GroupedCartItem[];
      const productIds = groupedItems
        .flatMap((group) => group.items.map((item) => item.productId))
        .filter((id, index, self) => self.indexOf(id) === index);

      const availableQuantities = await fetchAllQuantities(productIds);
      const updatedGroupedItems = groupedItems.map((group) => ({
        ...group,
        items: group.items.map((item) => ({
          ...item,
          availableQuantity: availableQuantities[item.productId] || 0,
        })),
      }));

      setGroupedCartItems(updatedGroupedItems);

      const sellerIds = Array.from(
        new Set(groupedItems.map((group: GroupedCartItem) => group.categoryUserId))
      ) as number[];
      fetchSellerNames(sellerIds);
      fetchTotal();
    } catch (error) {
      console.error('Error fetching cart items:', error);
      setError('Failed to load cart items, please log in.');
    } finally {
      setLoading(false);
    }
  };

  const fetchTotal = async () => {
    const { userId, securityCode } = getUserDataFromToken();
    if (!userId || !securityCode) {
      setTotal(0);
      return;
    }
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Cart/total`,
        {
          params: { userId, securityCode }, // Automatically appends query parameters
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      setTotal(response.data.total || 0);
    } catch (error) {
      console.error('Error fetching total price:', error);
      setError('Failed to load total price');
    }
  };
  

  const handleUpdateQuantity = async (productId: number, newQuantity: number) => {
    const { userId, securityCode } = getUserDataFromToken();
    if (!userId || !securityCode) {
      toast.error('User is not logged in.');
      return;
    }

    const product = groupedCartItems
      .flatMap((group) => group.items)
      .find((item) => item.productId === productId);

    if (!product) {
      toast.error('Product not found in cart.');
      return;
    }

    if (newQuantity < 1) {
      toast.warn('Quantity cannot be less than 1.');
      return;
    }

    if (newQuantity > product.availableQuantity) {
      toast.warn(`Only ${product.availableQuantity} units of ${product.productName} are available.`);
      return;
    }

    try {
      // UpdateCartQuantity: PUT /Cart/update-quantity with { userId, productId, quantity, securityCode } in body
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/Cart/update-quantity`,
        { userId: Number(userId), productId, quantity: newQuantity, securityCode },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      fetchCartItemsGrouped();
      fetchTotal();
    } catch (error) {
      console.error('Error updating quantity:', error);
      toast.error('Failed to update quantity.');
    }
  };

  const handleRemoveItem = async (cartItemId: number) => {
    const { userId, securityCode } = getUserDataFromToken();
    if (!userId || !securityCode) {
      toast.error('User is not logged in.');
      return;
    }

    try {
      // RemoveFromCart: DELETE /Cart/remove/{cartItemId}?userId=&securityCode=
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/Cart/remove/${cartItemId}?userId=${encodeURIComponent(userId)}&securityCode=${encodeURIComponent(securityCode)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      toast.success('Item removed from cart.');
      fetchCartItemsGrouped();
      fetchTotal();
    } catch (error) {
      console.error('Error removing item from cart:', error);
      toast.error('Failed to remove item from cart.');
    }
  };

  const handleCheckoutWithSeller = async (categoryUserId: number) => {
    const { userId, securityCode } = getUserDataFromToken();
    if (!userId || !securityCode) {
      toast.error('User is not logged in.');
      return;
    }

    localStorage.setItem('CategoryUserIdOne', categoryUserId.toString());

    const itemsToCheckout = groupedCartItems
      .find((group) => group.categoryUserId === categoryUserId)
      ?.items.map((item) => ({
        productId: item.productId,
        quantity: item.quantity,
        price: item.price,
      }));

    const itemIds = itemsToCheckout
      ? itemsToCheckout.flatMap((item) => Array(item.quantity).fill(item.productId))
      : [];

    const interimOrderDto = {
      userId: parseInt(userId),
      sellerId: categoryUserId,
      totalPrice:
        itemsToCheckout?.reduce((acc, item) => acc + item.price * item.quantity, 0) || 0,
      items: itemsToCheckout,
      itemIds: JSON.stringify(itemIds),
    };

    try {
      // Assuming interim-order endpoint is unchanged and does not require security code
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Order/interim-order`,
        interimOrderDto,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );

      console.log('Server response:', response.data);

      if (response.status === 200 && response.data.interimOrderId) {
        const interimOrderId = response.data.interimOrderId;
        localStorage.setItem('InterimOrderID_Summary', interimOrderId.toString());
        toast.success('Interim order successfully placed!');

        navigate('/CheckoutPart1');
      } else {
        throw new Error('Failed to create interim order or missing interimOrderId');
      }
    } catch (error) {
      console.error('Error during interim checkout:', error);
      toast.error('Failed to complete interim checkout.');
    }
  };

  useEffect(() => {
    fetchCartItemsGrouped();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="cart-loading-container">
        <div className="cart-loading-spinner"></div>
        <p>Loading Cart...</p>
      </div>
    );
  }

  return (
    <div className="cart-page-container">
      <Header />
      <div className="cart-page">
        <ToastContainer />
        {error && <p className="cart-error-message">{error}</p>}
        <div className="cart-content">
          <div className="cart-items-section">
            <h1 className="cart-title">Your Cart</h1>
            {groupedCartItems.length === 0 ? (
              <p className="cart-empty-message">Your cart is empty.</p>
            ) : (
              groupedCartItems.map((group) => (
                <div key={group.categoryUserId} className="cart-seller-group">
                  <h3 className="cart-seller-title">
                    Seller: {sellerNames[group.categoryUserId] || 'Unknown Seller'}
                  </h3>
                  <div className="cart-items-list">
                    {group.items.map((item) => (
                      <div key={item.id} className="cart-item-card">
                        <img
                          src={
                            item.imageUrl.startsWith('http')
                              ? item.imageUrl
                              : `${process.env.REACT_APP_API_BASE_URL}${item.imageUrl}`
                          }
                          alt={item.productName}
                          className="cart-item-image"
                          onError={(e) => {
                            e.currentTarget.src =
                              'https://via.placeholder.com/150?text=Image+Not+Available';
                            e.currentTarget.alt = 'Placeholder image';
                          }}
                        />
                        <div className="cart-item-details">
                          <h4 className="cart-item-name">{item.productName}</h4>
                          <p className="cart-item-price">
                            Price: R {item.price.toFixed(2)}
                          </p>
                          <div className="cart-item-quantity">
                            <label>Quantity:</label>
                            <div className="quantity-controls">
                              <button
                                onClick={() => handleUpdateQuantity(item.productId, item.quantity - 1)}
                                disabled={item.quantity <= 1}
                                className="quantity-button"
                              >
                                -
                              </button>
                              <input
                                type="number"
                                value={item.quantity}
                                min="1"
                                max={item.availableQuantity}
                                onChange={(e) => {
                                  const newQuantity = parseInt(e.target.value);
                                  if (isNaN(newQuantity)) return;

                                  if (newQuantity < 1) {
                                    toast.warn('Quantity cannot be less than 1.');
                                    return;
                                  }
                                  if (newQuantity > item.availableQuantity) {
                                    toast.warn(
                                      `Only ${item.availableQuantity} units of ${item.productName} are available.`
                                    );
                                    return;
                                  }
                                  handleUpdateQuantity(item.productId, newQuantity);
                                }}
                                className="quantity-input"
                              />
                              <button
                                onClick={() => handleUpdateQuantity(item.productId, item.quantity + 1)}
                                disabled={item.quantity >= item.availableQuantity}
                                className="quantity-button"
                              >
                                +
                              </button>
                            </div>
                            {item.availableQuantity <= 10 && (
                              <p className="available-stock">
                                Available: {item.availableQuantity}
                              </p>
                            )}
                          </div>
                        </div>
                        <button
                          onClick={() => handleRemoveItem(item.id)}
                          className="cart-item-remove-button"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="cart-seller-checkout">
                    <button
                      className="cart-checkout-button"
                      onClick={() => handleCheckoutWithSeller(group.categoryUserId)}
                    >
                      Checkout with {sellerNames[group.categoryUserId] || 'Seller'}
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="cart-summary-section">
            <h2 className="cart-summary-title">Total Summary</h2>
            <div className="cart-summary-content">
              <div className="cart-summary-row">
                <span>Subtotal</span>
                <span>R {total ? total.toFixed(2) : '0.00'}</span>
              </div>
              <div className="cart-summary-total">
                <span>Total</span>
                <span>R {total ? total.toFixed(2) : '0.00'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cart;

import React, { useState, useEffect } from 'react';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Address/ManageAddress.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from "jwt-decode";

interface Address {
  id: number;
  recipientName: string;
  recipientMobileNumber: string;
  street: string;
  complexBuilding?: string;
  businessName?: string;
  suburb: string;
  city: string;
  province: string;
  postalCode: string;
  isBusinessAddress: boolean;
}

interface DecodedToken {
  userId: string;
  [key: string]: any;
}

// Function to get userId from the JWT token
const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const ManageAddress: React.FC = () => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [currentAddress, setCurrentAddress] = useState<Address | null>(null);
  const [formValues, setFormValues] = useState<Address>({
    id: 0,
    recipientName: '',
    recipientMobileNumber: '',
    street: '',
    complexBuilding: '',
    businessName: '',
    suburb: '',
    city: '',
    province: '',
    postalCode: '',
    isBusinessAddress: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState<'residential' | 'business'>('residential');

  const fetchAddresses = async () => {
    const userId = getUserIdFromToken();
    if (!userId) {
      setError('User ID not found. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Address/user-addresses/${userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch addresses');
      }
      const data = await response.json();
      setAddresses(data);
    } catch (err) {
      console.error('Error fetching addresses:', err);
      setError('Failed to load addresses');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const userId = getUserIdFromToken();
    if (!userId) {
      toast.error('User ID not found. Please log in again.');
      return;
    }

    const method = currentAddress ? 'PUT' : 'POST';
    const endpoint = currentAddress
      ? `${process.env.REACT_APP_API_BASE_URL}/Address/update/${currentAddress.id}`
      : `${process.env.REACT_APP_API_BASE_URL}/Address/add`;

    try {
      const response = await fetch(endpoint, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({ ...formValues, userId, isBusinessAddress: activeTab === 'business' }),
      });

      if (!response.ok) {
        throw new Error('Failed to save address');
      }

      toast.success('Address saved successfully!');
      fetchAddresses(); // Refresh address list
      resetForm();
    } catch (error) {
      console.error('Error saving address:', error);
      toast.error('Failed to save address');
    }
  };

  const handleEdit = (address: Address) => {
    setCurrentAddress(address);
    setFormValues(address);
    setActiveTab(address.isBusinessAddress ? 'business' : 'residential');
  };

  const handleDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this address?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Address/remove/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete address');
        }

        toast.success('Address deleted successfully!');
        fetchAddresses(); // Refresh address list
      } catch (error) {
        console.error('Error deleting address:', error);
        toast.error('Failed to delete address');
      }
    }
  };

  const resetForm = () => {
    setCurrentAddress(null);
    setFormValues({
      id: 0,
      recipientName: '',
      recipientMobileNumber: '',
      street: '',
      complexBuilding: '',
      businessName: '',
      suburb: '',
      city: '',
      province: '',
      postalCode: '',
      isBusinessAddress: false,
    });
  };

  const handleTabClick = (tab: 'residential' | 'business') => {
    setActiveTab(tab);
    resetForm();
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="address-loading-container">
          <div className="address-loading-spinner"></div>
          <p>Loading addresses...</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="manage-address-page">
        <ToastContainer />
        {error && <p className="address-error-message">{error}</p>}
        <h1 className="manage-address-title">Manage Addresses</h1>

        {/* Tabs for Residential and Business Addresses */}
        <div className="address-tab-container">
          <button
            className={`address-tab-button ${activeTab === 'residential' ? 'active' : ''}`}
            onClick={() => handleTabClick('residential')}
          >
            Residential Address
          </button>
          <button
            className={`address-tab-button ${activeTab === 'business' ? 'active' : ''}`}
            onClick={() => handleTabClick('business')}
          >
            Business Address
          </button>
        </div>

        {/* Form based on the active tab */}
        <form onSubmit={handleSubmit} className="address-form">
          <div className="form-row">
            <input
              type="text"
              name="recipientName"
              value={formValues.recipientName}
              onChange={handleChange}
              placeholder="Recipient Name"
              required
            />
            <input
              type="text"
              name="recipientMobileNumber"
              value={formValues.recipientMobileNumber}
              onChange={handleChange}
              placeholder="Recipient Mobile Number"
              required
            />
          </div>
          <div className="form-row">
            <input
              type="text"
              name="street"
              value={formValues.street}
              onChange={handleChange}
              placeholder="Street Address"
              required
            />
            <input
              type="text"
              name="complexBuilding"
              value={formValues.complexBuilding || ''}
              onChange={handleChange}
              placeholder="Complex / Building (Optional)"
            />
          </div>
          {activeTab === 'business' && (
            <div className="form-row">
              <input
                type="text"
                name="businessName"
                value={formValues.businessName || ''}
                onChange={handleChange}
                placeholder="Business Name"
                required
              />
            </div>
          )}
          <div className="form-row">
            <input
              type="text"
              name="suburb"
              value={formValues.suburb}
              onChange={handleChange}
              placeholder="Suburb"
              required
            />
            <input
              type="text"
              name="city"
              value={formValues.city}
              onChange={handleChange}
              placeholder="City"
              required
            />
          </div>
          <div className="form-row">
            <input
              type="text"
              name="province"
              value={formValues.province}
              onChange={handleChange}
              placeholder="Province"
              required
            />
            <input
              type="text"
              name="postalCode"
              value={formValues.postalCode}
              onChange={handleChange}
              placeholder="Postal Code"
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="address-form-button">
              {currentAddress ? 'Update Address' : 'Add Address'}
            </button>
            {currentAddress && (
              <button type="button" onClick={resetForm} className="address-form-button cancel-button">
                Cancel
              </button>
            )}
          </div>
        </form>

        {/* Displaying saved addresses */}
        <h2 className="saved-addresses-title">Saved Addresses</h2>
        <div className="address-list">
          {addresses.map(address => (
            <div key={address.id} className="address-card">
              <div className="address-card-details">
                <p>
                  <strong>{address.recipientName}</strong> ({address.recipientMobileNumber})
                </p>
                {address.businessName && <p>Business: {address.businessName}</p>}
                <p>
                  {address.street}
                  {address.complexBuilding && `, ${address.complexBuilding}`}
                </p>
                <p>
                  {address.suburb}, {address.city}, {address.province}, {address.postalCode}
                </p>
              </div>
              <div className="address-card-actions">
                <button onClick={() => handleEdit(address)} className="address-card-button edit-button">
                  Edit
                </button>
                <button onClick={() => handleDelete(address.id)} className="address-card-button delete-button">
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ManageAddress;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/Shipping/ShippingManagement.css';
import { jwtDecode } from "jwt-decode";

interface ShippingDto {
  id?: number;
  userId: number;
  shippingMethodName: string;
  description: string;
  price: number;
  estimatedDeliveryTime: string;
}

interface DecodedToken {
  userId: string;
  [key: string]: any;
}

// Function to get userId from the JWT token
const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const ShippingManagement: React.FC = () => {
  const [shippingMethods, setShippingMethods] = useState<ShippingDto[]>([]);
  const [newShipping, setNewShipping] = useState<ShippingDto>({
    userId: parseInt(getUserIdFromToken() || '0'), // Retrieve userId from the JWT
    shippingMethodName: '',
    description: '',
    price: 0,
    estimatedDeliveryTime: '',
  });

  const fetchShippingMethods = async () => {
    const userId = getUserIdFromToken();
    if (!userId) {
      toast.error('User not logged in');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Shipping/user/${userId}`);
      setShippingMethods(response.data);
    } catch (error) {
      toast.error('Failed to fetch shipping methods');
      console.error('Error fetching shipping methods:', error);
    }
  };

  useEffect(() => {
    fetchShippingMethods();
  }, []);

  const handleAddShipping = async () => {
    const userId = getUserIdFromToken();
    if (!userId) {
      toast.error('User not logged in');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/Shipping/add`, { ...newShipping, userId: parseInt(userId) });
      toast.success('Shipping method added successfully');
      fetchShippingMethods();
      setNewShipping({
        userId: parseInt(userId),
        shippingMethodName: '',
        description: '',
        price: 0,
        estimatedDeliveryTime: '',
      });
    } catch (error) {
      toast.error('Failed to add shipping method');
      console.error('Error adding shipping method:', error);
    }
  };

  const handleUpdateShipping = async (shipping: ShippingDto) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/Shipping/update/${shipping.id}`, shipping);
      toast.success('Shipping method updated successfully');
      fetchShippingMethods();
    } catch (error) {
      toast.error('Failed to update shipping method');
      console.error('Error updating shipping method:', error);
    }
  };

  const handleDeleteShipping = async (id: number) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/Shipping/delete/${id}`);
      toast.success('Shipping method deleted successfully');
      fetchShippingMethods();
    } catch (error) {
      toast.error('Failed to delete shipping method');
      console.error('Error deleting shipping method:', error);
    }
  };

  return (
    <div className="shipping-management">
      <Header />
      <div className="shipping-content">
        <div className="shipping-header">
          <h2>Shipping Management</h2>
        </div>
        <div className="shipping-section">
          <div className="add-shipping">
            <h3>Add New Shipping Method</h3>
            <div className="form-group">
              <label htmlFor="shippingMethodName">Shipping Method Name</label>
              <input
                type="text"
                id="shippingMethodName"
                placeholder="Enter shipping method name"
                value={newShipping.shippingMethodName}
                onChange={(e) =>
                  setNewShipping({ ...newShipping, shippingMethodName: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                placeholder="Enter description"
                value={newShipping.description}
                onChange={(e) =>
                  setNewShipping({ ...newShipping, description: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="price">Price (R)</label>
              <input
                type="number"
                id="price"
                placeholder="Enter price"
                value={newShipping.price}
                onChange={(e) =>
                  setNewShipping({ ...newShipping, price: parseFloat(e.target.value) })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="estimatedDeliveryTime">Estimated Delivery Time</label>
              <input
                type="text"
                id="estimatedDeliveryTime"
                placeholder="Enter estimated delivery time"
                value={newShipping.estimatedDeliveryTime}
                onChange={(e) =>
                  setNewShipping({ ...newShipping, estimatedDeliveryTime: e.target.value })
                }
              />
            </div>
            <button className="add-button" onClick={handleAddShipping}>
              Add Shipping Method
            </button>
          </div>

          <div className="existing-shipping">
            <h3>Existing Shipping Methods</h3>
            <div className="shipping-list">
              {shippingMethods.map((shipping) => (
                <div key={shipping.id} className="shipping-item">
                  <div className="shipping-details">
                    <h4>{shipping.shippingMethodName}</h4>
                    <p>{shipping.description}</p>
                    <p>
                      <strong>Price:</strong> R{shipping.price.toFixed(2)}
                    </p>
                    <p>
                      <strong>Delivery Time:</strong> {shipping.estimatedDeliveryTime}
                    </p>
                  </div>
                  <div className="button-group">
                    <button
                      className="update-button"
                      onClick={() => handleUpdateShipping(shipping)}
                    >
                      Update
                    </button>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteShipping(shipping.id!)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              {shippingMethods.length === 0 && (
                <p className="no-shipping">No shipping methods available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer className="toast-container" />
    </div>
  );
};

export default ShippingManagement;
